import React, { useLayoutEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/apiHelper";
import { useDispatch } from "react-redux";
import { useProfile } from "../Components/Hooks/UserHooks";
import { logoutAction } from "../store/actions";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();

  useLayoutEffect(() => {
    if (userProfile && !loading && token) setAuthorization(token);
    else if (!userProfile && loading && !token) dispatch(logoutAction());
  }, []);

  /*
    redirect is un-auth access protected routes via url
  */
  if (!userProfile && loading && !token)
    return <Navigate to={{ pathname: "/", state: { from: props.location } }} />;

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
