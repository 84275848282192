import WhatsappImg from "../../assets/images/whatsapp-chat.png";
import React from "react";

const WhatsappChat = ({ whatsappNum, title }) => {
  return (
    <React.Fragment>
      <a
        className="whatsapp-chat"
        rel="noopener"
        href={`https://wa.me/${whatsappNum}`}
        target="_blank"
      >
        <button type="button" className="whatsapp-btn w-100 mt-2">
          {title}
          <img src={WhatsappImg} alt="whatsapp-icon" height="30px" />
        </button>
      </a>
    </React.Fragment>
  );
};

export default WhatsappChat;
