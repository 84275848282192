const WithdrawIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 18 18'
      fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.9375 1.5C0.9375 1.18934 1.18934 0.9375 1.5 0.9375H16.5C16.8107 0.9375 17.0625 1.18934 17.0625 1.5C17.0625 1.81066 16.8107 2.0625 16.5 2.0625H15.5625V9.20249C15.5625 9.24211 15.5625 9.2812 15.5625 9.31979C15.563 10.2099 15.5633 10.8289 15.3111 11.3844C15.0589 11.9398 14.5925 12.3471 13.9219 12.9329C13.8928 12.9583 13.8634 12.984 13.8335 13.0101L12.305 14.3466C11.6233 14.9427 11.0715 15.4251 10.5832 15.7537C10.0749 16.0958 9.57725 16.3125 9 16.3125C8.42275 16.3125 7.92506 16.0958 7.41677 15.7537C6.92847 15.4251 6.3767 14.9427 5.695 14.3466L4.16647 13.0101C4.13663 12.984 4.10718 12.9583 4.07811 12.9329C3.40746 12.3471 2.94112 11.9398 2.68891 11.3844C2.43668 10.8289 2.437 10.2099 2.43746 9.31978C2.43748 9.2812 2.4375 9.24211 2.4375 9.20249V2.0625H1.5C1.18934 2.0625 0.9375 1.81066 0.9375 1.5ZM3.5625 2.0625V9.20249C3.5625 10.2576 3.57426 10.6132 3.71324 10.9192C3.85226 11.2254 4.11239 11.4684 4.90699 12.1632L6.40699 13.4747C7.12403 14.1017 7.62263 14.5363 8.04484 14.8204C8.45385 15.0956 8.73184 15.1875 9 15.1875C9.26816 15.1875 9.54615 15.0956 9.95516 14.8204C10.3774 14.5363 10.876 14.1017 11.593 13.4747L13.093 12.1632C13.8876 11.4684 14.1477 11.2254 14.2868 10.9192C14.4257 10.6132 14.4375 10.2576 14.4375 9.20249V2.0625H3.5625ZM5.8125 6C5.8125 5.68934 6.06434 5.4375 6.375 5.4375H11.625C11.9357 5.4375 12.1875 5.68934 12.1875 6C12.1875 6.31066 11.9357 6.5625 11.625 6.5625H6.375C6.06434 6.5625 5.8125 6.31066 5.8125 6ZM5.8125 9.75C5.8125 9.43934 6.06434 9.1875 6.375 9.1875H11.625C11.9357 9.1875 12.1875 9.43934 12.1875 9.75C12.1875 10.0607 11.9357 10.3125 11.625 10.3125H6.375C6.06434 10.3125 5.8125 10.0607 5.8125 9.75Z'
        fill='white'
      />
    </svg>
  );
};

export default WithdrawIcon;
