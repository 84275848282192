const LoginIcon = () => {
  return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2410 4873 c-596 -62 -1092 -402 -1355 -931 -375 -752 -127 -1673
575 -2139 437 -290 978 -360 1473 -192 457 155 828 507 1015 965 83 203 116
382 116 624 0 242 -33 421 -116 624 -240 585 -756 976 -1383 1046 -85 9 -249
11 -325 3z m460 -186 c477 -100 889 -434 1080 -876 90 -208 124 -372 125 -606
0 -249 -43 -446 -144 -658 -39 -81 -169 -288 -180 -287 -4 1 -28 38 -56 82
-62 101 -269 314 -370 380 -179 118 -374 194 -566 222 -523 77 -1054 -163
-1332 -601 -29 -45 -54 -82 -58 -83 -11 -1 -141 206 -180 287 -53 110 -102
263 -125 388 -26 138 -26 392 0 530 117 629 606 1119 1226 1229 131 24 145 24
310 21 114 -3 185 -11 270 -28z m-18 -1925 c323 -83 590 -289 759 -589 l24
-42 -30 -32 c-89 -93 -286 -225 -434 -289 -211 -91 -372 -124 -611 -124 -239
0 -400 33 -611 124 -148 64 -345 196 -433 289 -35 37 -34 39 51 169 150 228
413 417 676 487 141 38 198 44 352 40 114 -3 161 -9 257 -33z"/>
<path d="M2445 4228 c-90 -17 -204 -80 -279 -154 -219 -219 -220 -568 -2 -786
114 -114 233 -163 396 -163 159 0 284 50 391 158 174 174 216 421 109 643
-109 227 -360 350 -615 302z m279 -186 c91 -42 149 -100 194 -190 35 -72 37
-81 37 -172 0 -91 -2 -100 -37 -172 -45 -91 -103 -147 -196 -191 -61 -29 -76
-32 -162 -32 -86 0 -101 3 -162 32 -93 44 -151 100 -196 191 -35 72 -37 81
-37 171 0 87 3 102 32 163 40 85 97 147 172 186 78 42 122 52 213 48 61 -3 92
-11 142 -34z"/>
<path d="M1005 1348 c-90 -17 -204 -80 -279 -154 -217 -217 -220 -566 -6 -782
70 -70 121 -103 219 -141 l66 -26 633 -3 632 -3 25 25 c30 30 33 74 6 107
l-19 24 -629 5 -628 5 -67 32 c-93 44 -151 100 -196 191 -35 72 -37 81 -37
171 0 87 3 102 32 163 44 93 100 151 191 196 l76 37 1536 0 1536 0 76 -37 c91
-45 147 -103 191 -196 29 -61 32 -76 32 -162 0 -86 -3 -101 -32 -162 -44 -93
-100 -151 -191 -196 l-76 -37 -629 -5 -629 -5 -19 -24 c-26 -32 -24 -77 6
-106 l24 -25 599 0 c381 0 620 4 658 11 98 17 210 78 288 155 219 220 220 566
1 787 -76 77 -190 138 -289 156 -77 14 -3028 13 -3101 -1z"/>
<path d="M1545 1015 c-14 -13 -25 -36 -25 -50 0 -22 -3 -24 -19 -15 -46 24
-104 -2 -117 -54 -6 -26 10 -64 35 -83 14 -10 14 -14 -6 -32 -29 -28 -37 -57
-23 -91 16 -38 62 -57 101 -43 27 11 29 10 29 -13 0 -35 42 -74 80 -74 38 0
80 39 80 74 0 23 2 24 29 13 39 -14 85 5 101 43 14 34 6 63 -23 91 -20 18 -20
22 -6 32 25 19 41 57 35 83 -13 52 -71 78 -117 54 -16 -9 -19 -7 -19 15 0 36
-42 75 -80 75 -19 0 -40 -9 -55 -25z"/>
<path d="M2505 1015 c-14 -13 -25 -36 -25 -50 0 -22 -3 -24 -19 -15 -46 24
-104 -2 -117 -54 -6 -26 10 -64 35 -83 14 -10 14 -14 -6 -32 -29 -28 -37 -57
-23 -91 16 -38 62 -57 101 -43 27 11 29 10 29 -13 0 -35 42 -74 80 -74 38 0
80 39 80 74 0 23 2 24 29 13 39 -14 85 5 101 43 14 34 6 63 -23 91 -20 18 -20
22 -6 32 25 19 41 57 35 83 -13 52 -71 78 -117 54 -16 -9 -19 -7 -19 15 0 36
-42 75 -80 75 -19 0 -40 -9 -55 -25z"/>
<path d="M3465 1015 c-14 -13 -25 -36 -25 -50 0 -22 -3 -24 -19 -15 -46 24
-104 -2 -117 -54 -6 -26 10 -64 35 -83 14 -10 14 -14 -6 -32 -29 -28 -37 -57
-23 -91 16 -38 62 -57 101 -43 27 11 29 10 29 -13 0 -35 42 -74 80 -74 38 0
80 39 80 74 0 23 2 24 29 13 39 -14 85 5 101 43 14 34 6 63 -23 91 -20 18 -20
22 -6 32 25 19 41 57 35 83 -13 52 -71 78 -117 54 -16 -9 -19 -7 -19 15 0 36
-42 75 -80 75 -19 0 -40 -9 -55 -25z"/>
<path d="M2505 375 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
-80 80 -19 0 -40 -9 -55 -25z"/>
</g>
</svg>
  );
};

export default LoginIcon;
