import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { Deposit } from "../pages/User/Deposit";
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage";

const Events = lazy(() => import("../pages/Sport/Events"));
const Event = lazy(() => import("../pages/Sport/Event"));
const BlockedIp = lazy(() => import("../pages/Blocked"));
const UserProfile = lazy(() => import("../pages/User/UserProfile"));
const AccountStatement = lazy(() => import("../pages/User/AccountStatement"));
const AccountWithdraw = lazy(() => import("../pages/User/AccountWithdrawal"));
const OpenBets = lazy(() => import("../pages/User/OpenBets"));
const StakeButton = lazy(() => import("../pages/User/StakeButton"));
const TransactionHistory = lazy(() => import("../pages/User/TransactionHistory"));
// const Bouns = lazy(() => import("../pages/User/Bouns"));
const Casino = lazy(() => import("../pages/Casino"));
const CasinoEvent = lazy(() => import("../pages/Casino/event"));
const CasinoIndian = lazy(() => import("../pages/Casino/indian"));

// const SportsBook = lazy(() => import("../pages/Sport/SportsBook"));
const LoginPage = lazy(() => import("../pages/Auth/LoginPage"));
const ForgotPasswordPage = lazy(() => import("../pages/Auth/ForgotPasswordPage"));
const Rules = lazy(() => import("../pages/User/Rules"));

const authProtectedRoutes = [
  { path: "/sport", component: <Events /> },
  { path: "/sport/:sportID", component: <Events /> },
  { path: "/sport/:sportID/:sportName", component: <Events /> },
  { path: "/casino", component: <Casino /> },
  { path: "/event/:eventID/:compititionName/:eventName", component: <Event /> },

  { path: "/account/user-profile", component: <UserProfile /> },
  { path: "/account/account-statement", component: <AccountStatement /> },
  { path: "/account/account-withdraw", component: <AccountWithdraw /> },
  { path: "/account/open-bets", component: <OpenBets /> },
  { path: "/account/transaction-history", component: <TransactionHistory /> },
  // { path: "/account/bonus", component: <Bouns /> },
  { path: "/account/stakebutton", component: <StakeButton /> },
  {
    path: "/account",
    exact: true,
    component: <Navigate to="/account/user-profile" />,
  },
  { path: "/account/rules", component: <Rules /> },
  { path: "/casino/:casinoID/:casinoName", component: <CasinoEvent /> },
  { path: "/indian-casino", component: <CasinoIndian /> },
  { path: "/indian-casino/:casinoID/:casinoName", component: <CasinoIndian /> },
  // { path: "/sportsbook", component: <SportsBook /> },

  { path: "/user/deposit", component: <Deposit /> },
];

const publicRoutes = [
  { path: "/", exact: true, component: <LoginPage /> },
  { path: "/login", exact: true, component: <LoginPage /> },
  { path: "/reset-password", exact: true, component: <ResetPasswordPage /> },
  { path: "/forgotPassword", exact: true, component: <ForgotPasswordPage /> },
  { path: "/blocked", exact: true, component: <BlockedIp /> },
];

export { authProtectedRoutes, publicRoutes };
