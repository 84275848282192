import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCasinoList,
  getCasinoEvent,
  getCasinoProvidersList,
  getCasinoGameTypeList,
} from "../../helpers/apiHelper/apis";

export const getCasinos = createAsyncThunk("Casino/getCasinos", async () => {
  const response = await getCasinoList();
  return response.data;
});

export const getCasinoEventId = createAsyncThunk("Casino/getCasinoEvent", async (params) => {
  const response = await getCasinoEvent(params);
  return response.data;
});

export const getCasinoProviders = createAsyncThunk("Casino/getCasinoProviders", async (params) => {
  const response = await getCasinoProvidersList(params);
  return response.data;
});

export const getCasinoGameType = createAsyncThunk("Casino/getCasinoGameType", async (params) => {
  const response = await getCasinoGameTypeList(params);
  return response.data;
});
