export function customGroupBy(array, key, sortField = "") {
  const map = new Map();

  array.forEach((ele) => {
    const groupKey = ele[key];

    if (!map.has(groupKey)) {
      map.set(groupKey, {
        id: groupKey,
        title: ele?.eventType || "",
        data: [],
      });
    }
    map.get(groupKey).data.push(ele);
  });

  const result = Array.from(map.values());
  if (sortField) {
    result.sort((a, b) =>
      a.title === sortField ? -1 : b.title === sortField ? 1 : 0
    );
  }

  return result;
}

export const getStartEndDayDate = (startDate, endDate) => {
  let sDate = new Date(startDate);
  let eDate = new Date(endDate);

  sDate = new Date(
    sDate.getFullYear(),
    sDate.getMonth(),
    sDate.getDate(),
    0,
    0,
    0,
    0
  );
  eDate = new Date(
    eDate.getFullYear(),
    eDate.getMonth(),
    eDate.getDate(),
    23,
    59,
    59,
    999
  );

  return { sDate, eDate };
};

export const roundToTwoDecimalPlaces = (value) => {
  if (!value) return "";
  return Math.round(value * 100) / 100;
};
