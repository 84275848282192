import { createSlice } from "@reduxjs/toolkit";
import {
  getSportTypeList,
  getSportsBook,
  getRules,
  getAllEvents,
  getSiteRules,
  getEventsByEventType,
} from "./action";
import { toast } from "react-toastify";
import _ from "underscore";

const initialState = {
  sportsType: [],
  sportBook: {},
  eventTypeRules: [],
  siteRules: [],
  allEvents: [],
  inplay: [],
  sportEvents: {},
  sportsTypes: [],
  compBySport: [],
  eventBYComp: [],
  eventBYSport: {},
};

const sportSlice = createSlice({
  name: "Sport",
  initialState,
  reducers: {
    getSportTypeList: (state, action) => {
      state.sportsType = action.payload.items;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSportTypeList.fulfilled, (state, action) => {
      state.sportsTypes = action.payload;
    });

    builder
      .addCase(getSportsBook.fulfilled, (state, action) => {
        state.sportBook = action.payload;
      })
      .addCase(getSportsBook.rejected, (state, action) => {
        toast.error(action.error.message ? action.error.message : "SportBook NotWorking");
      });

    builder.addCase(getRules.fulfilled, (state, action) => {
      state.eventTypeRules = action.payload.data;
    });
    builder.addCase(getSiteRules.fulfilled, (state, action) => {
      state.siteRules = action.payload.data;
    });

    builder.addCase(getAllEvents.fulfilled, (state, action) => {
      state.allEvents = action.payload;
      state.inplay = _.filter(action.payload, (x) => x.inPlay);
      state.sportEvents = _.groupBy(action.payload, (x) => x.eventTypeId);

      let competitions = _.reduce(
        action.payload,
        (acc, sport) => {
          acc.push({
            compititionId: sport.competitionsId,
            compititionName: sport.compititionName,
            eventTypeId: sport.eventTypeId,
          });
          return acc;
        },
        []
      );
      competitions = _.uniq(competitions, (x) => x.compititionId);
      state.compBySport = _.groupBy(competitions, (x) => x.eventTypeId);
      state.eventBYComp = _.groupBy(action.payload, (x) => x.competitionsId);
    });

    builder.addCase(getEventsByEventType.fulfilled, (state, action) => {
      if (Object.keys(action.payload).length) {
        const key = Object.keys(action.payload)[0];
        if (!state.eventBYSport?.[key]) state.eventBYSport[key] = action.payload[key];
      }
    });
  },
});

export default sportSlice.reducer;
