import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, FormFeedback } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import BankTransfer from "../../../assets/svg/BankTransfer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPlusCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import {
  manualDepositAction,
  getAccountBalance,
  changeLoader,
  getPaymentMode,
} from "../../../store/actions";
import { useNavigate } from "react-router-dom";
import socket from "../../../sockets/socket";

export const DepositModule = ({ initialAmount }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { paymentMode } = useSelector((state) => ({
    paymentMode: state.Common.paymentMode,
  }));

  const [selectedImage, setSelectedImage] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [details, setDetails] = useState({});
  const [selected, setSelected] = useState("");
  const [update, setUpdate] = useState(false);
  const [mode, setMode] = useState("manual");
  const [copiedText, setCopiedText] = useState("");
  const [copyStatus, setCopyStatus] = useState(false);

  const getBalance = () => dispatch(getAccountBalance());

  useEffect(() => {
    getBalance();
    dispatch(getPaymentMode());
  }, []);

  useEffect(() => {
    if (paymentMode?.length) {
      setPaymentType(paymentMode[0].type);
      setDetails(paymentMode[0]);
      setSelected(paymentMode[0]._id);
    }
  }, [paymentMode]);

  useEffect(() => {
    socket.on("update-payment-mode", (data) => {
      dispatch(getPaymentMode());
    });

    return () => {
      socket.off("update-payment-mode");
    };
  }, []);

  const copyDetails = (text) => {
    setCopiedText(text);
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 5000);
  };

  const changePaymentMode = (paymentMode) => {
    setPaymentType(paymentMode.type);
    setDetails(paymentMode);
    setSelected(paymentMode._id);
  };

  const handlePaymentImgDragOver = (e) => {
    e.preventDefault();
  };

  const handlePaymentImgDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    validation.setFieldValue("paymentImage", droppedFile);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: initialAmount,
      utrId: "",
      paymentImage: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .min(details.min_amount, `Amount must be greater than or equal to ${details.min_amount}`)
        .max(details.max_amount, `Amount must be less than or equal to ${details.max_amount}`)
        .required("Amount is a required field"),
      utrId: Yup.string().required("Please Enter UTR Transaction Id"),
      paymentImage: Yup.mixed()
        .required("Please Updload Payment Screenshot")
        .test("fileSize", "File too large", (value) => {
          return value && value.size <= 1048576; // 1 MB
        })
        .test("type", "Unsupported Format", (value) => {
          return value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
        }),
    }),
    onSubmit: (values) => {
      const DepositData = new FormData();
      DepositData.append("paymentImage", values.paymentImage);
      DepositData.append("amount", Number(values.amount));
      DepositData.append("utrId", values.utrId);
      DepositData.append("paymentModeId", details._id);
      dispatch(changeLoader(true));
      dispatch(manualDepositAction(DepositData))
        .then((a) => {
          dispatch(changeLoader(false));
          if (a.payload) {
            validation.resetForm();
            setSelectedImage(null);
            navigate(-1);
          }
        })
        .catch((error) => {
          dispatch(changeLoader(false));
        });
    },
  });

  return (
    <React.Fragment>
      {paymentMode?.length ? (
        <div className=" deposit-modal modal-xl mx-lg-3 mt-lg-2" style={{ color: "black" }}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            className="deposit-form overflow-hidden"
          >
            <div>
              <div className="deposit-methods2 d-flex justify-content-start flex-wrap">
                {paymentMode.map((mode, key) => (
                  <div className="deposit-method-items" key={key}>
                    <input
                      type="radio"
                      id={mode._id}
                      name="depositMethods"
                      checked={selected == mode._id}
                      onChange={() => changePaymentMode(mode)}
                    />
                    {mode.type == "BANK" ? (
                      <React.Fragment>
                        <label htmlFor={mode._id}>
                          <BankTransfer />
                          <span>
                            Bank <br />
                            Tranfer
                          </span>
                        </label>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <label htmlFor={mode._id}>
                          <img src={mode.display_icon} alt="QR" />
                        </label>
                      </React.Fragment>
                    )}
                  </div>
                ))}
              </div>
              <div className="payment-tab-content">
                <p className="payment-infoText mt-0">Make Your Payment On The Details Below</p>
                <div className="row mb-3">
                  <div className="col-12 col-md-7 col-lg-8">
                    {paymentType == "BANK" ? (
                      <div className="payment-details mb-20 d-flex align-items-center">
                        <div className="col-12 px-0">
                          <div className="payment-details-row">
                            <p className="payment-details-left">Bank Name</p>
                            <p className="payment-details-right">
                              {details.bank_name}
                              <CopyToClipboard
                                text={details.bank_name}
                                onCopy={() => copyDetails(details.bank_name)}
                              >
                                <span className="copyIcon">
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{
                                      color:
                                        copyStatus && copiedText == details.bank_name
                                          ? "green"
                                          : "black",
                                    }}
                                  />
                                </span>
                              </CopyToClipboard>
                            </p>
                          </div>
                          <div className="payment-details-row">
                            <p className="payment-details-left">Account Holder Name</p>
                            <p className="payment-details-right">
                              {details.bank_account_holder_name}
                              <CopyToClipboard
                                text={details.bank_account_holder_name}
                                onCopy={() => copyDetails(details.bank_account_holder_name)}
                              >
                                <span className="copyIcon">
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{
                                      color:
                                        copyStatus && copiedText == details.bank_account_holder_name
                                          ? "green"
                                          : "black",
                                    }}
                                  />
                                </span>
                              </CopyToClipboard>
                            </p>
                          </div>
                          <div className="payment-details-row">
                            <p className="payment-details-left">Account Number</p>
                            <p className="payment-details-right">
                              {details.bank_account_number}{" "}
                              <CopyToClipboard
                                text={details.bank_account_number}
                                onCopy={() => copyDetails(details.bank_account_number)}
                              >
                                <span className="copyIcon">
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{
                                      color:
                                        copyStatus && copiedText == details.bank_account_number
                                          ? "green"
                                          : "black",
                                    }}
                                  />
                                </span>
                              </CopyToClipboard>
                            </p>
                          </div>
                          <div className="payment-details-row">
                            <p className="payment-details-left">Ifsc</p>
                            <p className="payment-details-right">
                              {details.bank_ifsc}{" "}
                              <CopyToClipboard
                                text={details.bank_ifsc}
                                onCopy={() => copyDetails(details.bank_ifsc)}
                              >
                                <span className="copyIcon">
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{
                                      color:
                                        copyStatus && copiedText == details.bank_ifsc
                                          ? "green"
                                          : "black",
                                    }}
                                  />
                                </span>
                              </CopyToClipboard>
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="payment-details mb-20 d-flex align-items-start flex-column flex-md-row">
                        <div className="col-12 col-md-7">
                          <div className="payment-details-row d-flex flex-row pt-3 align-items-start">
                            <p className="payment-details-left">Name</p>
                            <p className="payment-details-right">
                              {details.name}
                              <CopyToClipboard
                                text={details.name}
                                onCopy={() => copyDetails(details.name)}
                              >
                                <span className="copyIcon">
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    style={{
                                      color:
                                        copyStatus && copiedText == details.name
                                          ? "green"
                                          : "black",
                                    }}
                                  />
                                </span>
                              </CopyToClipboard>
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-5">
                          <div className="payment-details-row qr-row">
                            <p className="payment-details-left">Scan Qr Code </p>
                            <figure className="quCode-img m-0">
                              <img src={details.bank_qr_image} alt="qr code" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-12 px-0">
                      <div className="payment-details mb-10">
                        <div className="mb-2">
                          <Input
                            name="amount"
                            id="amount-field"
                            className="form-control"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount ?? ""}
                            invalid={validation.errors.amount ? true : false}
                          />
                          {validation.errors.amount ? (
                            <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                          ) : null}
                        </div>
                        <p className="deposit-amount-info">
                          You Can Add Upto <span>{details.max_amount}</span> Minimum{" "}
                          <span>{details.min_amount}</span> Required
                        </p>
                      </div>
                      <div className="payment-details">
                        <div className="mb-0">
                          <Input
                            name="utrId"
                            id="utrId-field"
                            className="form-control"
                            type="text"
                            placeholder="Enter UTR transaction id"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.utrId ?? ""}
                            invalid={validation.errors.utrId ? true : false}
                          />
                          {validation.errors.utrId ? (
                            <FormFeedback type="invalid">{validation.errors.utrId}</FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-5 col-lg-4 mt-3 mt-md-0">
                    <div className="payment-details h-100">
                      <div
                        className="add-payment-screenshot"
                        onDragOver={handlePaymentImgDragOver}
                        onDrop={handlePaymentImgDrop}
                      >
                        <Input
                          name="paymentImage"
                          id="paymentImage"
                          className={classNames("added-screenshot m-0", {
                            "d-none": !validation.values.paymentImage,
                          })}
                          type="file"
                          onChange={(event) => {
                            validation.setFieldValue("paymentImage", event.currentTarget.files[0]);
                          }}
                          onBlur={validation.handleBlur}
                          invalid={validation.errors.paymentImage ? true : false}
                          title=" "
                          placeholder=" "
                        />
                        <label htmlFor="paymentImage" className="add-screenshot-cover">
                          {validation.values.paymentImage ? (
                            <img
                              src={
                                validation.values.paymentImage
                                  ? URL.createObjectURL(validation.values.paymentImage)
                                  : null
                              }
                              alt="front"
                            />
                          ) : (
                            <>
                              <span className="addIcon">
                                <FontAwesomeIcon icon={faPlusCircle} />
                              </span>
                              <span className="addText">
                                Click here to upload Payment Screenshot
                              </span>{" "}
                            </>
                          )}
                        </label>
                        {validation.errors.paymentImage ? (
                          <FormFeedback type="invalid">
                            {validation.errors.paymentImage}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="deposit-end-section row">
                  <div className="col-12 col-sm-9 col-md-10 d-block d-sm-none">
                    <p className="info-dang-text text-danger mb-0">
                      <FontAwesomeIcon icon={faExclamationTriangle} /> <strong>Note:</strong> Our
                      bank accounts or payment details keep changing check the payment details
                      before depositing. If you deposited in other account witch is not currently
                      listed on site then we are not responsible for your loss.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-2">
                <div className="col-12 col-sm-9 col-md-10 d-none d-sm-block">
                  <p className="info-dang-text text-danger mb-0">
                    <FontAwesomeIcon icon={faExclamationTriangle} /> <strong>Note:</strong> Our bank
                    accounts or payment details keep changing check the payment details before
                    depositing. If you deposited in other account witch is not currently listed on
                    site then we are not responsible for your loss.
                  </p>
                </div>
                <div className="col align-items-center d-flex justify-content-end">
                  <button type="submit" className="btn-primary btn active">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <div className="text-center">No Payment mode available</div>
      )}
    </React.Fragment>
  );
};
