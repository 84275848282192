import React from "react";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer-main" id="footer">
        <footer className="app-footer">
          <div className="app-footer-copyright p-10 text-center">
            <h5>deltaexch.games</h5>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default Footer;
