import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BetlistComponent from "../Components/Common/BetlistComponent";
import { Link } from "react-router-dom";
import { api } from "../config";
import { decrypt } from "../helpers/methods/encryptDecrypt";
import { APIHelper } from "../helpers/apiHelper";
import { toast } from "react-toastify";
import useWindowDimensions from "../helpers/methods/screeenSize";

const SideBanner = () => {
  const [liveTV, setLiveTV] = useState(false);
  const [tvFrame, setTVFrame] = useState(null);

  const { isLoggedIn, event, balance } = useSelector((state) => ({
    isLoggedIn: state.User.isLoggedIn,
    event: state.Market.event,
    balance: state.Account.balance,
  }));
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setTVFrame(null);
  }, [height, width]);

  const showLiveTV = () => {
    if (balance < 100) {
      toast.error("Minimum balance of 100 required to watch live TV.");
      return;
    }
    if (!liveTV) {
      const data = localStorage.getItem("_GE_IED_");
      const ipData = decrypt(data);

      APIHelper.postNoHeader(api.LIVE_TV, {
        channel: event?.event.mainTVurl,
        ipv4: ipData.ip,
      }).then((e) => {
        if (e.status == 1 && e.code == 200) setTVFrame(e.data);
      });
    }
    setLiveTV(!liveTV);
  };

  return (
    <React.Fragment>
      <div className="sideBanner-grid">
        <div className="sidebaradd-inner">
          <div className="right-card">
            {event?.event?.mainTVurl && (
              <>
                <div className="right-card-heading">
                  <Link onClick={showLiveTV} className="text-light">
                    Live TV
                  </Link>
                </div>
                {liveTV && tvFrame && width > 767 ? (
                  <div className="right-card-content">
                    <div
                      className="liveTV-box"
                      dangerouslySetInnerHTML={{ __html: tvFrame }}
                    />
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className="mobile-view-d-none">
            {isLoggedIn ? <BetlistComponent /> : ""}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SideBanner;
