import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  manualDeposit,
  getBalance,
  getStatement,
  getTransactionHistoryApi,
} from "../../helpers/apiHelper/apis";

export const manualDepositAction = createAsyncThunk(
  "Wallet/manualDeposit",
  async (data) => {
    return await manualDeposit(data);
  }
);

export const getAccountBalance = createAsyncThunk(
  "Wallet/getAccountBalance",
  async (data) => {
    const response = await getBalance(data);
    return response.data;
  }
);

export const getStatementList = createAsyncThunk(
  "Account/getStatementList",
  async (data) => {
    const response = await getStatement(data);
    return response.data;
  }
);
export const getTransactionHistory = createAsyncThunk(
  "User/transaction-History/getTransactionHistory",
  async (data) => {
    const response = await getTransactionHistoryApi(data);

    return response.data;
  }
);
