const UserProfileIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.0001 0.9375C7.03259 0.9375 5.4376 2.53249 5.4376 4.5C5.4376 6.46751 7.03259 8.0625 9.0001 8.0625C10.9676 8.0625 12.5626 6.46751 12.5626 4.5C12.5626 2.53249 10.9676 0.9375 9.0001 0.9375ZM6.5626 4.5C6.5626 3.15381 7.65391 2.0625 9.0001 2.0625C10.3463 2.0625 11.4376 3.15381 11.4376 4.5C11.4376 5.84619 10.3463 6.9375 9.0001 6.9375C7.65391 6.9375 6.5626 5.84619 6.5626 4.5Z'
        fill='white'
      />
      <path
        d='M14.8917 10.9297C15.1147 11.146 15.12 11.5022 14.9036 11.7251L13.5288 13.1418C13.4177 13.2562 13.2632 13.3181 13.1038 13.3121C12.9445 13.3061 12.7951 13.2327 12.693 13.1101L12.0679 12.3601C11.869 12.1215 11.9012 11.7668 12.1398 11.5679C12.3785 11.369 12.7332 11.4012 12.9321 11.6399L13.1569 11.9096L14.0963 10.9416C14.3127 10.7187 14.6688 10.7133 14.8917 10.9297Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0811 9.38468C10.4265 9.25654 9.72644 9.1875 9.0001 9.1875C7.26494 9.1875 5.66629 9.58191 4.48169 10.2483C3.31471 10.9047 2.4376 11.8995 2.4376 13.125L2.43755 13.2015C2.4367 14.0729 2.43564 15.1665 3.39491 15.9477C3.86701 16.3321 4.52746 16.6055 5.41976 16.7861C6.31455 16.9672 7.48077 17.0625 9.0001 17.0625C11.1512 17.0625 12.6075 16.8721 13.5898 16.5064C14.474 16.1772 14.998 15.695 15.2779 15.0879C16.3446 14.4724 17.0626 13.3201 17.0626 12C17.0626 10.0325 15.4676 8.4375 13.5001 8.4375C12.5663 8.4375 11.7164 8.79679 11.0811 9.38468ZM11.0626 12C11.0626 10.6538 12.1539 9.5625 13.5001 9.5625C14.8463 9.5625 15.9376 10.6538 15.9376 12C15.9376 13.3462 14.8463 14.4375 13.5001 14.4375C12.1539 14.4375 11.0626 13.3462 11.0626 12ZM10.3169 10.3987C10.0742 10.8801 9.9376 11.4241 9.9376 12C9.9376 13.792 11.2607 15.2749 12.9832 15.5253C12.1802 15.7775 10.9336 15.9375 9.0001 15.9375C7.51943 15.9375 6.43565 15.8439 5.64294 15.6835C4.84774 15.5225 4.38319 15.3016 4.10529 15.0753C3.59284 14.658 3.5626 14.1059 3.5626 13.125C3.5626 12.4865 4.02863 11.7939 5.03323 11.2288C6.0202 10.6736 7.42156 10.3125 9.0001 10.3125C9.45464 10.3125 9.89557 10.3425 10.3169 10.3987Z'
        fill='white'
      />
    </svg>
  );
};

export default UserProfileIcon;
