import React, { useEffect, Suspense } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../Layouts/index";
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";
import { useSelector } from "react-redux";

const Index = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector((state) => ({
    isLoggedIn: state.User.isLoggedIn,
  }));

  useEffect(() => {
    if (isLoggedIn && pathname == "/") navigate("/sport");
  }, []);
  return (
    <MainLayout>
      <React.Fragment>
        <Routes>
          <Route>
            {publicRoutes.map((route, idx) => {
              return (
                <Route
                  path={route.path}
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      {route.component}
                    </Suspense>
                  }
                  key={idx}
                  exact={true}
                />
              );
            })}
          </Route>

          <Route>
            {authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <AuthProtected>
                    <Suspense fallback={<div>Loading...</div>}>
                      {route.component}
                    </Suspense>
                  </AuthProtected>
                }
                key={idx}
                exact={true}
              />
            ))}
          </Route>
        </Routes>
      </React.Fragment>
    </MainLayout>
  );
};

export default Index;
