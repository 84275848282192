// // Front
import { combineReducers } from "redux";
import layoutReducer from "./layouts/reducer";
import Market from "./market/reducer";
import Sport from "./sports/reducer";
import User from "./user/reducer";
import Bet from "./bet/reducer";
import Account from "./account/reducer";
import Common from "./common/reducer";
import Filter from "./filter/reducer";
import Casino from "./casino/reducer";
import Settings from "./generalSettings/reducer";

const rootReducer = combineReducers({
  // public
  Layout: layoutReducer,
  Market,
  Filter,
  Sport,
  User,
  Bet,
  Account,
  Common,
  Casino,
  Settings,
});

export default rootReducer;
