import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSportTypeList as getSportTypeListApi,
  getEventsByEventType as getEventsByEventTypeAPI,
  getSportsBook as getSportsBookAPI,
  getRulesApi,
  getAllEvents as getAllEventsApi,
  getEvents as getEventsApi,
  getSiteRulesAPI,
} from "../../helpers/apiHelper/apis";

export const getSportTypeList = createAsyncThunk("Sport/getSportTypeList", async () => {
  const response = await getSportTypeListApi();
  return response.data;
});

export const getEventsByEventType = createAsyncThunk(
  "Sport/getEventsByEventType",
  async (payload) => {
    const response = await getEventsByEventTypeAPI(payload);
    return { [payload]: response.data };
  }
);

export const getSportsBook = createAsyncThunk("Sport/getSportsBook", async (payload) => {
  const response = await getSportsBookAPI(payload);
  return response.data;
});

export const getRules = createAsyncThunk("Sports/eventTypeRule/getRules", async () => {
  const response = await getRulesApi();
  return response;
});

export const getSiteRules = createAsyncThunk("Sports/eventTypeRule/getSiteRules", async () => {
  const response = await getSiteRulesAPI();
  return response;
});

export const getAllEvents = createAsyncThunk("Sport/getAllEvent", async () => {
  const response = await getAllEventsApi();
  return response.data;
});

// export const getEvents = createAsyncThunk("Sport/getEvents", async () => {
//   const response = await getEventsApi();
//   return response.data;
// });
