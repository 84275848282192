import axios from "axios";
import { api } from "../../config";
import { postRefreshToken } from "./apis";
import { ClientJS } from "clientjs";
import { encrypt, decrypt } from "../methods/encryptDecrypt";
import * as url from "./url_helper";

const client = new ClientJS();

const browser = client.getBrowser();
const browserVersion = client.getBrowserVersion();
const fingerprint = client.getFingerprint();
const isMobile = client.isMobile();

let ipData = localStorage.getItem("_GE_IED_");
if (ipData) ipData = decrypt(ipData);

const headers = { "Content-Type": "application/json" };

let updateHeaders = {
  "Content-Type": "application/json",
  "X-Client-Browser": browser,
  "X-Client-Browser-Version": browserVersion,
  "X-Client-Fingerprint": fingerprint,
  "X-Client-Device": isMobile ? "MOBILE" : "WEB",
};

if (ipData) {
  updateHeaders = {
    ...updateHeaders,
    ...{
      "X-Client-IP": ipData?.ip ? ipData?.ip : fingerprint,
    },
  };
}

const apiRequest = axios.create({
  baseURL: api.API_URL,
  headers,
});

// content type
const token = JSON.parse(localStorage.getItem("authUser"))
  ? JSON.parse(localStorage.getItem("authUser")).token
  : null;
if (token)
  apiRequest.defaults.headers.common["Authorization"] = "Bearer " + token;

/**
 * Sets the default authorization setRefreshToken
 * @param {*} token
 */
const setRefreshToken = (token) => {
  apiRequest.defaults.headers.common["Authorization"] = "Bearer " + token;
  apiRequest.interceptors.request.use((config) => {
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  });
};

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token, newUserLogin = false) => {
  apiRequest.interceptors.request.use((config) => {
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  });

  if (newUserLogin) {
    updateHeaders = {
      ...updateHeaders,
      ...{
        Authorization: "Bearer " + token,
      },
    };
  }
  apiRequest.defaults.headers.common["Authorization"] = "Bearer " + token;
};

/**
 * Sets the client headers
 * @param {*} token
 */
const updateHeader = (ipData) => {
  updateHeaders = {
    ...updateHeaders,
    ...{
      "X-Client-IP": ipData?.ip ? ipData?.ip : fingerprint,
    },
  };

  apiRequest.interceptors.request.use((config) => {
    config.headers = {
      ...updateHeaders,
      ...{
        "X-Client-IP": ipData?.ip ? ipData?.ip : fingerprint,
      },
    };
    return config;
  });

  apiRequest.defaults.headers.common["X-Client-IP"] = ipData?.ip
    ? ipData?.ip
    : fingerprint;
};

apiRequest.interceptors.request.use(
  (config) => {
    let userProfileSession = getLoggedinUser();
    if (userProfileSession) {
      const { accessToken } = userProfileSession;
      if (accessToken) {
        if (config.url != url.GET_IP)
          config.headers = {
            ...updateHeaders,
            ...{
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/json",
            },
          };
        else
          config.headers = {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          };

        if (config.url.includes("place-bet")) {
          config.headers = {
            ...config.headers,
            ...{ "X-Client-Signature": `${config.data.signature}` },
          };
          delete config.data.signature;
          config.data = { data: encrypt(config.data) };
        }
      }
    } else {
      if (config.url?.includes("/v1/user/password/update")) {
        updateHeaders = {
          ...updateHeaders,
          ...{
            Authorization:
              apiRequest?.defaults?.headers?.common?.["Authorization"],
          },
        };
      } else {
        delete updateHeaders["Authorization"];
      }
      if (config.url != url.GET_IP) config.headers = updateHeaders;
      else config.headers = { Accept: "application/json" };
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// intercepting to capture errors
let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

apiRequest.interceptors.response.use(
  (response) => {
    return response.data ? response.data : response;
  },
  async (error) => {
    const originalRequest = error.config;
    const userProfileSession = getLoggedinUser();

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          return new Promise((resolve) => {
            addRefreshSubscriber((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(apiRequest(originalRequest));
            });

            postRefreshToken({ refreshToken: userProfileSession.refreshToken })
              .then((response) => {
                if (response) {
                  const newToken = response.data.accessToken;
                  localStorage.setItem(
                    "authUser",
                    JSON.stringify(response.data)
                  );
                  setAuthorization(newToken);
                  onRefreshed(newToken);
                } else {
                  localStorage.removeItem("authUser");
                  window.location = "/login";
                }
              })
              .catch((err) => {
                localStorage.removeItem("authUser");
                window.location = "/login";
                return Promise.reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } catch (err) {
          isRefreshing = false;
          localStorage.removeItem("authUser");
          window.location = "/login";
          return Promise.reject(err);
        }
      } else {
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(apiRequest(originalRequest));
          });
        });
      }
    }

    switch (error?.response?.status) {
      case 500:
        return Promise.reject("Internal Server Error");
      case 401:
        return Promise.reject("Invalid credentials");
      case 404:
        return Promise.reject(
          "Sorry! the data you are looking for could not be found"
        );
      default:
        return Promise.reject(
          error.response?.data?.message || error.message || error
        );
    }
  }
);

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return apiRequest.get(url, params);
  // };
  get = (url, params) => {
    let response;
    let paramKeys = [];
    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });
      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = apiRequest.get(`${url}?${queryString}`);
    } else {
      response = apiRequest.get(`${url}`);
    }

    return response;
  };

  /**
   * post given data to url
   */
  post = (url, data) => {
    return apiRequest.post(url, data);
  };

  postNoHeader = (url, data) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    return apiRequest.post(url, data, headers);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return apiRequest.patch(url, data);
  };

  put = (url, data) => {
    return apiRequest.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return apiRequest.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const APIHelper = new APIClient();
export { APIHelper, setAuthorization, getLoggedinUser, updateHeader };
