module.exports = {
  api: {
    API_URL: process.env.REACT_APP_API_URL,
    BASE_URL: process.env.PUBLIC_URL,
    SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
    IP_URL: process.env.REACT_APP_LOCATION_URL,
    IP2_URL: process.env.REACT_APP_LOCATION2_URL,
    LIVE_TV: process.env.REACT_APP_LIVE_TV_URL,
    AURA_OPERATOR_ID: process.env.REACT_APP_AURA_OPERATOR_ID,
    AURA_CASIO_M: process.env.REACT_APP_AURA_CASIO_M,
    AURA_CASIO_D: process.env.REACT_APP_AURA_CASIO_D,
    AURA_CASIO: process.env.REACT_APP_AURA_CASIO,
  },
};
