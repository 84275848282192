import { createSlice } from "@reduxjs/toolkit";
import {
  getDefaultStakeButtons,
  getPaymentMode,
  updateStakeButtons,
} from "../actions";

const initialState = {
  defaultStakebuttons: [],
};

const commonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {},
  paymentMode: [],
  extraReducers: (b) => {
    b.addCase(getDefaultStakeButtons.fulfilled, (state, action) => {
      if (action?.payload) {
        state.defaultStakebuttons = action.payload;
      }
    });
    b.addCase(updateStakeButtons.fulfilled, (state, action) => {
      if (action?.payload) {
        state.defaultStakebuttons = action.payload;
      }
    });
    b.addCase(getPaymentMode.fulfilled, (state, action) => {
      if (action?.payload) {
        state.paymentMode = action.payload.data;
      }
    });
  },
});

export default commonSlice.reducer;
