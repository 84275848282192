import { createSlice } from "@reduxjs/toolkit";
import { getCasinoEventId, getCasinoGameType, getCasinoProviders, getCasinos } from "./action";
import { toast } from "react-toastify";

const initialState = {
  casinos: [],
  casinoProviders: [],
  casinoGameTypes: [],
  casinoEvent: {},
  popularCasinos: [],
};

const casinoSlice = createSlice({
  name: "Casino",
  initialState,
  reducers: {
    resetCasino: function (state, action) {
      state.casinoEvent = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCasinos.fulfilled, (state, action) => {
      state.casinos = action.payload;
      state.popularCasinos = action.payload.filter((item) => item.isPopular && item.isActive);
    });

    builder
      .addCase(getCasinoEventId.fulfilled, (state, action) => {
        state.casinoEvent = action.payload;
      })
      .addCase(getCasinoEventId.rejected, (state, action) => {
        toast.error("Error in loading Casino. Please try another casino");
        throw new Error("Error in loading Casino. Please try another casino");
      });

    builder.addCase(getCasinoProviders.fulfilled, (state, action) => {
      state.casinoProviders = action.payload;
    });
    builder.addCase(getCasinoGameType.fulfilled, (state, action) => {
      state.casinoGameTypes = action.payload;
    });
  },
});

export const { resetCasino } = casinoSlice.actions;

export default casinoSlice.reducer;
