import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DepositModule } from "./DepositModule";
import { useNavigate } from "react-router-dom";
import WhatsappChat from "../../../Components/Common/WhatsappChat";
import { GENERAL_SETTINGS } from "../../../helpers/constants";

export const Deposit = () => {
  const [selectAmount, setSelectAmount] = useState([
    100, 200, 500, 1000, 2000, 5000,
  ]);
  const [whatsappNum, setWhatsappNum] = useState("");
  const { generalSettings } = useSelector((state) => ({
    generalSettings: state.Settings?.generalSettings,
  }));
  const [startDeposit, setStartDeposit] = useState(false);
  const navigate = useNavigate();

  const handleAmount = (amount) => {
    validation.setFieldValue("amount", amount);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .min(100, "Amount must be greater than or equal to 100")
        .max(500000, "Amount must be less than or equal to 500000")
        .required("Amount is a required field"),
    }),
    onSubmit: (values) => {
      setStartDeposit(true);
    },
  });

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const whatsapp_num =
      generalSettings?.[GENERAL_SETTINGS.SOCIAL_MEDIA]?.metaData?.depositWhatsAppNum;
    if (whatsapp_num) setWhatsappNum(whatsapp_num);
  }, [generalSettings]);

  return (
    <React.Fragment>
      {startDeposit ? (
        <DepositModule initialAmount={validation.values.amount} />
      ) : (
        <div className="deposit-init-modal" data-bs-theme="dark">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="b-r-8 mb-10">
              <p className="deposit-text-clr"> Enter Deposit Amount </p>
              <div className="mb-2">
                <Input
                  name="amount"
                  id="amount-field"
                  className="form-control"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.amount ?? ""}
                  invalid={validation.errors.amount ? true : false}
                />
                {validation.errors.amount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.amount}
                  </FormFeedback>
                ) : null}
              </div>
            </div>

            <div className="deposit-methods d-flex mb-9">
              {selectAmount.map((amount, index) => (
                <div
                  className="deposit-amount-btn"
                  key={index}
                  onClick={() => handleAmount(amount)}
                >
                  {amount}
                </div>
              ))}
            </div>

            <button type="submit" className="btn btn-primary active w-100 mt-2">
              Continue To Payment
            </button>
            <button
              type="button"
              onClick={handleBack}
              className="btn btn-danger w-100 mt-2"
            >
              Cancel
            </button>
            {generalSettings?.[GENERAL_SETTINGS.SOCIAL_MEDIA]
              ?.metaData?.isDepositWhatsappActive &&
              whatsappNum && (
                <WhatsappChat
                  whatsappNum={whatsappNum}
                  title={"FOR PAYMENT RELATED ISSUES CLICK HERE"}
                />
              )}
          </Form>
        </div>
      )}
    </React.Fragment>
  );
};
