import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import _ from "underscore";
import { getEventsByEventType } from "../store/actions";
import { toast } from "react-toastify";
import {
  getGeneralSettings,
  siteMaintenanceSetting,
  casinoSettings,
} from "../store/generalSettings/action";

const Sidebar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const {
    sportsTypes,
    compBySport,
    eventBYComp,
    eventBYSport,
    casinos,
    isCasinoLock,
    generalSettings,
  } = useSelector((state) => ({
    sportsTypes: state.Sport.sportsTypes,
    compBySport: state.Sport.compBySport,
    eventBYComp: state.Sport.eventBYComp,
    eventBYSport: state.Sport.eventBYSport,
    casinos: state.Casino.casinos,
    isCasinoLock: state.User.User?.isCasinoLock,
    generalSettings: state.Settings?.generalSettings,
  }));
  const toogleMenuBtn = () => {
    document.querySelector(".hamburger-icon").classList.toggle("open");
    document.querySelector(".main").classList.toggle("sidebar-close");
    document.querySelector(".app-footer").classList.toggle("sidebar-close");
  };

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedCom, setSelectedCom] = useState(null);

  const handleEventSideMenu = (id) => {
    if (selectedEvent === id) {
      setSelectedEvent(null);
      setSelectedCom(null);
      return;
    }
    setSelectedEvent(id);
    var sport = _.filter(sportsTypes, function (x) {
      return x._id === id;
    });

    if (sport && sport.length && [11, 6].includes(sport[0].referenceId))
      dispatch(getEventsByEventType(id));
  };

  const handleCompSideMenu = (id) => {
    if (selectedCom === id) {
      setSelectedCom(null);
      return;
    }
    setSelectedCom(id);
  };

  const fetchGeneralSettings = () => {
    dispatch(getGeneralSettings());
    dispatch(siteMaintenanceSetting());
    dispatch(casinoSettings());
  };

  useEffect(() => {
    fetchGeneralSettings();
  }, []);

  const redirectToCasino = (type) => {
    if (isCasinoLock)
      toast.error(
        "You Don't Have Access to Casino, Please Contact your Administrator."
      );
    else {
      if (type === "casino") navigate("/casino");
      else navigate("/indian-casino");
    }
  };

  return (
    <React.Fragment>
      <div className="overlay" id="close-sidebar" onClick={toogleMenuBtn}></div>
      <aside className="sidebar-cover">
        <ul className="firstUl main-sidebar">
          <li className="sidebar-nav nav-heading">
            <Link title="All Sports">
              <span className="sidebarNav d-flex align-items-center gap-2">
                All Sports
                {/* <FontAwesomeIcon size="xs" icon={faListUl} /> */}
              </span>
            </Link>
          </li>
          {sportsTypes && sportsTypes.length ? (
            <React.Fragment>
              {sportsTypes?.map((item, key) => (
                <React.Fragment key={key}>
                  <li
                    className={classNames("sidebar-nav", {
                      "opened-nav": selectedEvent === item._id,
                    })}
                    onClick={() => handleEventSideMenu(item._id)}
                  >
                    <Link title={item._id} className="opened-nav">
                      <span className="sidebarNav ps-1 d-flex align-items-center gap-1">
                        {item.name}
                        {selectedEvent === item._id ? (
                          <FontAwesomeIcon size="xs" icon={faChevronDown} />
                        ) : (
                          <FontAwesomeIcon size="xs" icon={faChevronRight} />
                        )}
                      </span>
                    </Link>
                  </li>
                  <Collapse isOpen={selectedEvent === item._id}>
                    <ul className="firstUl individual-games">
                      {(eventBYSport[selectedEvent] || []).map((x, y) => (
                        <li className="sidebar-nav" key={y}>
                          <Link
                            title={x.eventName}
                            to={`/event/${x?._id}/${encodeURI(
                              x?.eventName
                            )}/${encodeURI(x?.eventName)}`}
                          >
                            <span
                              className="sidebarNav d-flex align-items-center gap-1 text-wrap"
                              key={y}
                            >
                              {x.eventName}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                  <Collapse isOpen={selectedEvent === item._id}>
                    {compBySport[item._id] && compBySport[item._id].length ? (
                      <ul className="firstUl">
                        {compBySport[item._id].map((data, i) => (
                          <React.Fragment key={i}>
                            <li
                              className="sidebar-nav"
                              onClick={() =>
                                handleCompSideMenu(data.compititionId)
                              }
                            >
                              <Link title={data.compititionName}>
                                <span
                                  className="sidebarNav d-flex align-items-center gap-1 text-wrap"
                                  key={i}
                                >
                                  {data.compititionName}
                                  {selectedCom === data.compititionId ? (
                                    <FontAwesomeIcon
                                      size="xs"
                                      icon={faChevronDown}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      size="xs"
                                      icon={faChevronRight}
                                    />
                                  )}
                                </span>
                              </Link>
                            </li>
                            <Collapse
                              isOpen={selectedCom === data.compititionId}
                            >
                              <ul className="firstUl individual-games">
                                {eventBYComp[data.compititionId].map((x, y) => (
                                  <li className="sidebar-nav" key={y}>
                                    <Link
                                      title={x.eventName}
                                      to={`/event/${x?._id}/${encodeURI(
                                        x?.compititionName
                                      )}/${encodeURI(x?.eventName)}`}
                                    >
                                      <span
                                        className="sidebarNav d-flex align-items-center gap-1 text-wrap"
                                        key={i}
                                      >
                                        {x.eventName}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </Collapse>
                          </React.Fragment>
                        ))}
                      </ul>
                    ) : null}
                  </Collapse>
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : null}

          {casinos &&
          casinos.length &&
          generalSettings?.casino?.metaData?.isActive ? (
            <React.Fragment>
              <li
                className={classNames("sidebar-nav", {
                  "opened-nav": selectedEvent === "casinos",
                })}
                onClick={() => handleEventSideMenu("casinos")}
              >
                <Link title={"Casinos"} className="opened-nav">
                  <span className="sidebarNav ps-1 d-flex align-items-center gap-1">
                    Casinos
                    {selectedEvent === "casinos" ? (
                      <FontAwesomeIcon size="xs" icon={faChevronDown} />
                    ) : (
                      <FontAwesomeIcon size="xs" icon={faChevronRight} />
                    )}
                  </span>
                </Link>
              </li>
              <Collapse isOpen={selectedEvent === "casinos"}>
                <ul className="firstUl individual-games">
                  {generalSettings?.casino?.metaData
                    ?.isInternationalCasinoActive && (
                    <li className="sidebar-nav">
                      <div
                        onClick={() => redirectToCasino("casino")}
                        className="opened-nav"
                      >
                        <span className="sidebarNav ps-1 d-flex align-items-center gap-1">
                          Casino
                        </span>
                      </div>
                    </li>
                  )}
                  {generalSettings?.casino?.metaData?.isIndianCasinoActive && (
                    <li className="sidebar-nav">
                      <div
                        onClick={() => redirectToCasino("indian-casino")}
                        className="opened-nav"
                      >
                        <span className="sidebarNav ps-1 d-flex align-items-center gap-1">
                          Indian Casino
                        </span>
                      </div>
                    </li>
                  )}
                </ul>
              </Collapse>
            </React.Fragment>
          ) : null}
        </ul>
      </aside>
    </React.Fragment>
  );
};

export default Sidebar;
