import CryptoJS from "crypto-js";
// const crypto = require("crypto");

const secretKey =
  process.env.REACT_APP_ENCRYPT_KEY ??
  "U2FsdGVkX180t3ska2JZISYVvuNFH21wEFiZLNiiT9KI9bJhYibLn2giGXPcmaRB";
const iv = process.env.REACT_APP_ENCRYPT_IV ?? "7123543e2629b094f0e3dd48c4d3723415";

export const encrypt = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  return encryptedData;
};

export const decrypt = (encrypted) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encrypted, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (e) {
    console.log("error in dec", e);
  }
};
