import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";

// Icons
import Avatar01 from "../../assets/images/Avatar.png";
import UserIcon from "./../../assets/svg/UserIcon";
import EditIcon from "../../assets/svg/EditIcon";
import UserProfileIcon from "../../assets/svg/UserProfileIcon";
import AccountStatementIcon from "../../assets/svg/AccountStatementIcon";
import WithdrawIcon from "../../assets/svg/WithdrawIcon";
import TransactionIcon from "../../assets/svg/TransactionIcon";
import BonusIcon from "../../assets/svg/BonusIcon";
import OpenBetIcon from "../../assets/svg/OpenBetIcon";
import SignOutIcon from "../../assets/svg/SignOutIcon";
import { logoutAction } from "../../store/actions";

const ProfileMenuMobile = () => {
  const dispatch = useDispatch();
  const { User } = useSelector((state) => state);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => setIsProfileDropdown(!isProfileDropdown);

  return (
    <React.Fragment>
      <li className="nav-item d-block d-lg-none">
        <div className="nav-link">
          <div className="navOuter dropdownMobile">
            <div className="d-flex justify-content-center">
              <div className="header-drodwn d-inline-flex mobileHeader_dropdown mb-1">
                <Dropdown
                  isOpen={isProfileDropdown}
                  toggle={toggleProfileDropdown}
                  className="profile-dropdown"
                >
                  <DropdownToggle
                    tag="button"
                    type="button"
                    className="btn-no-bg nav-profile  align-items-center justify-content-center ms-2 dropdown-toggle"
                  >
                    <div className="">
                      <UserIcon />
                    </div>
                  </DropdownToggle>

                  <DropdownMenu className="p-10 dropdown-menu">
                    <div className="drp-profile-img d-flex align-items-end flex-wrap">
                      <figure className="m-0 profileimg-circle">
                        <div className="profile-inner-circle">
                          <img src={Avatar01} alt="user" />
                        </div>
                        <span className="online-status"></span>
                      </figure>
                      <div className="profile-name ms-3">
                        <p className="m-0">{User?.User?.username}</p>
                      </div>
                      <div className="edit-img ms-auto">
                        <Link to={"/account/user-profile"} onClick={() => toggleProfileDropdown()}>
                          <EditIcon />
                        </Link>
                      </div>
                    </div>
                    <ul className="profile-nav-link m-0">
                      <li>
                        <Link to={"/account/user-profile"} onClick={() => toggleProfileDropdown()}>
                          <UserProfileIcon />
                          User Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/account/account-statement"}
                          onClick={() => toggleProfileDropdown()}
                        >
                          <AccountStatementIcon />
                          Account Statement
                        </Link>
                      </li>

                      <li>
                        <Link
                          to={"/account/transaction-history"}
                          onClick={() => toggleProfileDropdown()}
                        >
                          <TransactionIcon />
                          Transaction History
                        </Link>
                      </li>
                      {/* <li>
                        <Link to={"/account/bonus"}>
                          <BonusIcon />
                          Bonus List
                        </Link>
                      </li> */}
                      <li>
                        <Link to={"/account/open-bets"} onClick={() => toggleProfileDropdown()}>
                          <OpenBetIcon />
                          Open Bets
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"} onClick={() => dispatch(logoutAction())}>
                          <SignOutIcon />
                          Sign Out
                        </Link>
                      </li>
                    </ul>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <span className="nav__item-text p-0"> Profile</span>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default ProfileMenuMobile;
