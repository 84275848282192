const CrossIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 18 18'
      fill='none'>
      <g clipPath='url(#clip0_498_10798)'>
        <path
          d='M7.52272 6.72724C7.30305 6.50757 6.9469 6.50757 6.72723 6.72724C6.50756 6.94691 6.50756 7.30307 6.72723 7.52274L8.20449 9L6.72725 10.4772C6.50758 10.6969 6.50758 11.0531 6.72725 11.2727C6.94692 11.4924 7.30307 11.4924 7.52274 11.2727L8.99998 9.79549L10.4772 11.2727C10.6969 11.4924 11.053 11.4924 11.2727 11.2727C11.4924 11.0531 11.4924 10.6969 11.2727 10.4772L9.79548 9L11.2727 7.52275C11.4924 7.30308 11.4924 6.94693 11.2727 6.72726C11.0531 6.50759 10.6969 6.50759 10.4772 6.72726L8.99998 8.2045L7.52272 6.72724Z'
          fill='#7E7E7E'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9 0.9375C4.5472 0.9375 0.9375 4.5472 0.9375 9C0.9375 13.4528 4.5472 17.0625 9 17.0625C13.4528 17.0625 17.0625 13.4528 17.0625 9C17.0625 4.5472 13.4528 0.9375 9 0.9375ZM2.0625 9C2.0625 5.16852 5.16852 2.0625 9 2.0625C12.8315 2.0625 15.9375 5.16852 15.9375 9C15.9375 12.8315 12.8315 15.9375 9 15.9375C5.16852 15.9375 2.0625 12.8315 2.0625 9Z'
          fill='#7E7E7E'
        />
      </g>
      <defs>
        <clipPath id='clip0_498_10798'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CrossIcon;
