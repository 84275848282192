import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMarketByEventIdApi,
  getMarketOddsAPI,
  getLimitSetting as getLimitSettingAPI,
} from "../../helpers/apiHelper/apis";

export const subscribeMarket = createAsyncThunk(
  "Market/subscribeMarket",
  async (state, action) => {}
);

export const getMarketsByEventId = createAsyncThunk(
  "Market/getMarketsByEventId",
  async (payload) => {
    return await getMarketByEventIdApi(payload);
  }
);
export const getMarketOdds = createAsyncThunk(
  "Market/getMarketOdds",
  async (payload) => {
    const response = await getMarketOddsAPI(payload);

    return response.data;
  }
);

export const getLimitSetting = createAsyncThunk("Market/getLimitSetting", async (payload) => {
  const response = await getLimitSettingAPI(payload);
  return response.data;
});
