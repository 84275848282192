import React, { useState, useEffect } from "react";
import {
  Form,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoginArrowIcon from "../../assets/svg/LoginArrowIcon";
import LoginUserIcon from "../../assets/svg/LoginUserIcon";
import LoginIcon from "../../assets/svg/LoginIcon";
import {
  loginViaUsernameAction,
  resetNewUserPasswordAction,
  resetPasswordAction,
} from "../../store/user/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { DEMO_USER, USER_TYPE } from "../../helpers/constants";
import { setNewUserStatus, setUserType } from "../../store/user/reducer";
import logo from "../../assets/images/logo.png";
import { getLoggedinUser } from "../../helpers/apiHelper";
import ResetPasswordModal from "../User/UserProfile/ResetPasswordModal";

function ResetPasswordPage() {
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState(null);

  const { isLoggedIn } = useSelector((state) => ({
    isLoggedIn: state.User.isLoggedIn,
  }));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn) navigate("/sport");
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required")
        .notOneOf(
          [Yup.ref("oldPassword")],
          "New password must not be equal to old password"
        ),
      confirmPassword: Yup.string()
        .when("newPassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            "Both password need to be the same"
          ),
        })
        .required("Confirm Password Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      dispatch(resetNewUserPasswordAction(values))
        .then((res) => {
          if (res.payload) {
            setNewUserStatus(false);
            navigate("/login");
            validation.resetForm();
            setLoading(false);
          }
        })
        .catch((error) => {
          validation.resetForm();
          setLoading(false);

          console.log(error);
        });
    },
  });

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm="12" md="4" lg="4">
          <div className="nav navbar">
            <div className="navbar-brand m-auto">
              <img src={logo} alt="deltaexch.games" className="logo" />
            </div>
          </div>
          <Form
            className="tablelist-form text-white bg-white p-3 shadow-sm"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3 ">
              <h4>
                <LoginIcon /> Reset Password
              </h4>
            </div>
            <div className="mb-3 ">
              <div className="d-flex align-items-start justify-content-between">
                <Label htmlFor="old-password-input">Old Password</Label>
              </div>
              <div className="position-relative ">
                <Input
                  type={oldPasswordShow ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter old password"
                  id="old-password-input"
                  name="oldPassword"
                  value={validation.values.oldPassword}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  invalid={
                    validation.errors.oldPassword &&
                    validation.touched.oldPassword
                      ? true
                      : false
                  }
                />
                {validation.errors.oldPassword &&
                validation.touched.oldPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.oldPassword}
                  </FormFeedback>
                ) : null}
                <button
                  className="btn border-0 position-absolute end-0 text-decoration-none text-muted"
                  type="button"
                  id="password-addon"
                  onClick={() => setOldPasswordShow(!oldPasswordShow)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              </div>
            </div>
            <div className="mb-3 ">
              <div className="d-flex align-items-start justify-content-between">
                <Label htmlFor="old-password-input">New Password</Label>
              </div>
              <div className="position-relative ">
                <Input
                  type={passwordShow ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter new password"
                  id="old-password-input"
                  name="newPassword"
                  value={validation.values.newPassword}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  invalid={
                    validation.errors.newPassword &&
                    validation.touched.newPassword
                      ? true
                      : false
                  }
                />
                {validation.errors.newPassword &&
                validation.touched.newPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.newPassword}
                  </FormFeedback>
                ) : null}
                <button
                  className="btn border-0 position-absolute end-0 text-decoration-none text-muted"
                  type="button"
                  id="password-addon"
                  onClick={() => setPasswordShow(!passwordShow)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              </div>
            </div>
            <div className="mb-3 ">
              <div className="d-flex align-items-start justify-content-between">
                <Label htmlFor="confirm-password-input">Confirm Password</Label>
              </div>
              <div className="position-relative ">
                <Input
                  type={confrimPasswordShow ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter confirm password"
                  id="confirm-password-input"
                  name="confirmPassword"
                  value={validation.values.confirmPassword}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  invalid={
                    validation.errors.confirmPassword &&
                    validation.touched.confirmPassword
                      ? true
                      : false
                  }
                />
                {validation.errors.confirmPassword &&
                validation.touched.confirmPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.confirmPassword}
                  </FormFeedback>
                ) : null}
                <button
                  className="btn border-0 position-absolute end-0 text-decoration-none text-muted"
                  type="button"
                  id="password-addon"
                  onClick={() => setConfrimPasswordShow(!confrimPasswordShow)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              </div>
            </div>

            <button type="submit" className="login-btn active w-100">
              <div>
                {loading ? (
                  <div className="d-flex align-items-center ">
                    <div className="d-flex justify-content-center gap-1 ms-auto">
                      <Spinner color="light" size="sm"></Spinner>
                      <span>Loading...</span>
                    </div>
                    <span className="ms-auto">
                      <LoginArrowIcon />
                    </span>
                  </div>
                ) : (
                  <div>
                    Reset Password
                    <span className="float-end">
                      <LoginArrowIcon />
                    </span>
                  </div>
                )}
              </div>
            </button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPasswordPage;
