import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileSection from "../Components/Common/ProfileSection";
import UserProfileMobile from "../Components/Common/UserProfileMobile";
import socket from "../sockets/socket";
import { setMarketData } from "../store/market/reducer";
import classNames from "classnames";
import logo from "../assets/images/logo.png";
import { toast } from "react-toastify";
import { getUserData, logoutAction } from "../store/actions";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();

  const { userId, sportsTypes, isCasinoLock, isLoggedIn, generalSettings } = useSelector(
    (state) => ({
      userId: state.User?.User?._id,
      sportsTypes: state.Sport.sportsTypes,
      isCasinoLock: state.User.User?.isCasinoLock,
      isLoggedIn: state.User.isLoggedIn,
      generalSettings: state.Settings?.generalSettings,
    })
  );

  const [active, setActive] = useState("");

  const toogleMenuBtn = () => {
    document.querySelector(".hamburger-icon").classList.toggle("open");
    document.querySelector(".main").classList.toggle("sidebar-close");
    document.querySelector(".app-footer").classList.toggle("sidebar-close");
  };

  useEffect(() => {
    if (isLoggedIn) dispatch(getUserData());
  }, [isLoggedIn]);

  const redirectToCasino = (type) => {
    if (isCasinoLock && type !== "/sport")
      toast.error("You Don't Have Access to Casino, Please Contact your Administrator.");
    else {
      if (location.pathname !== type) navigate(type);
    }
  };

  useEffect(() => {
    socket.on("rate", (updatedEventData) => {
      dispatch(setMarketData(updatedEventData));
    });

    socket.on("logout-user", (id) => {
      if (isLoggedIn && userId === id)
        dispatch(logoutAction()).then((r) => {
          if (r.payload) navigate("/");
        });
    });
    return () => {
      socket.off("logout-user");
    };
  }, [dispatch]);

  const checkActiveMenu = (type) => location.pathname.includes(`${type}`);
  const isIndianCasinoActive = generalSettings?.casinoToggle?.metaData?.isIndianCasinoActive;
  const isInternationalCasinoActive = generalSettings?.casinoToggle?.metaData?.isCasinoActive;
  return (
    <React.Fragment>
      <header className="app-header">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <button
              onClick={toogleMenuBtn}
              type="button"
              className="btn btn-sm border-0 d-lg-none topnav-hamburger"
              id="topnav-hamburger-icon"
            >
              <span className="hamburger-icon open">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>

            <Link
              to={"/sport"}
              title={process.env.REACT_APP_NAME}
              className="navbar-brand pt-0 pb-0 d-inline-flex"
            >
              <img src={logo} alt="deltaexch.games" className="logo" />
            </Link>

            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                <li
                  className={classNames("nav-item", {
                    active: location.pathname.includes("sport"),
                  })}
                >
                  <div onClick={() => redirectToCasino("/sport")} className="nav-link ">
                    InPlay
                  </div>
                </li>

                {/* <li
                  className={classNames("nav-item", {
                    active: location.pathname.includes("sportsbook"),
                  })}
                >
                  <Link to={"/sportsbook"} title="SportsBook" className="nav-link ">
                    SportsBook
                  </Link>
                </li> */}
                {isInternationalCasinoActive && (
                  <li
                    className={classNames("nav-item", {
                      active: location.pathname.includes("casino"),
                    })}
                  >
                    <div
                      title="Casino"
                      className="nav-link "
                      onClick={() => redirectToCasino("/casino")}
                    >
                      Casino
                    </div>
                  </li>
                )}
                {isIndianCasinoActive && (
                  <li
                    className={classNames("nav-item", {
                      active: location.pathname.includes("indian-casino"),
                    })}
                  >
                    <div
                      title="Indian Casino"
                      className="nav-link "
                      onClick={() => redirectToCasino("/indian-casino")}
                    >
                      Indian Casino
                    </div>
                  </li>
                )}
                {sportsTypes.map((item, i) => {
                  return (
                    <li
                      key={i}
                      onClick={() => setActive(item._id)}
                      className={classNames("nav-item", {
                        active: location.pathname.includes(item._id),
                      })}
                    >
                      <Link to={"/sport/" + item._id} title={item.name} className="nav-link">
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
                <UserProfileMobile />
              </ul>
            </div>
            <ProfileSection />
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
};

export default Header;
