import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import css

import "./pages/Auth/LoginPage.css";
import "./assets/scss/themes.scss";
import "./assets/scss/custom_theme.scss";
import "./assets/css/customise_theme.scss";
import "./assets/scss/components.scss";

//imoprt Route
import Route from "./Routes";
import { getIP, getIPAction } from "./store/actions";
import { decrypt, encrypt } from "./helpers/methods/encryptDecrypt";
import { updateHeader } from "./helpers/apiHelper";
import { api } from "./config";
import { ClientJS } from "clientjs";
import { publicIpv4 } from "public-ip";
import { useNavigate } from "react-router-dom";
import { addListener, launch } from "devtools-detector";
import { useIdleTimer } from "react-idle-timer";
import { GENERAL_SETTINGS } from "./helpers/constants";

const DifferentWarningComponent = () => <div>NO WAY!</div>;

const disableRightClick = (event) => {
  event.preventDefault();
};

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [devToolsStatus, setDevToolsStatus] = useState("close");
  const [geoData, setGeoData] = useState(null);

  const client = new ClientJS();

  const { loader, isLoggedIn, isNewUser, generalSettings } = useSelector((state) => ({
    loader: state.Layout.loader,
    isLoggedIn: state.User.isLoggedIn,
    isNewUser: state.User.isNewUser,
    generalSettings: state.Settings?.generalSettings,
  }));

  const handleOnIdle = (event) => {
    if (isLoggedIn) window.location.reload();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 4, // 4-Hour Idle timeout
    onIdle: handleOnIdle,
    crossTab: true,
    debounce: 500,
  });

  useLayoutEffect(() => {
    const geoData = localStorage.getItem("_GE_IED_");
    setGeoData(decrypt(geoData));
    if (!geoData) {
      const browser = client.getBrowser();
      const browserVersion = client.getBrowserVersion();
      const fingerprint = client.getFingerprint();
      const isMobile = client.isMobile();

      publicIpv4({
        fallbackUrls: [api.IP_URL, api.IP2_URL],
      }).then(async (e) => {
        const data = {
          ip: e,
          browser,
          browserVersion,
          fingerprint,
          deviceType: isMobile ? "MOBILE" : "WEB",
        };
        const geoData = encrypt(data);

        await localStorage.setItem("_GE_IED_", geoData);
        updateHeader(data);
      });
    }
  }, []);

  //add class to body
  useEffect(() => {
    const addClass = () => {
      document.body.classList.add("loader_loaded");
    };

    const removeClass = () => {
      document.body.classList.remove("loader_loaded");
    };

    if (loader) addClass();
    else removeClass();
    // window.addEventListener("load", () => {
    //   addClass();

    //   setTimeout(removeClass, 2000);
    // });

    // Cleanup the event listener on component unmount
    // return () => {
    //   window.removeEventListener("load", addClass);
    // };
  }, [loader]);

  useEffect(() => {
    document.addEventListener("contextmenu", disableRightClick);
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  const user = localStorage.getItem("authUser");
  useEffect(() => {
    if (isNewUser) {
      navigate("/reset-password");
    } else {
      if (!isLoggedIn) navigate("/login");
      else if (!user) navigate("/login");
    }
  }, [isLoggedIn, user]);

  useEffect(() => {
    const handleStorageChange = () => {
      if (localStorage.getItem("authUser")) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // useEffect(() => {
  //   const handleDevToolsStatusChange = (isOpen) => {
  //     // if (process.env.REACT_APP_ENVIRONMENT == "production")
  //     setDevToolsStatus(isOpen ? "open" : "close");
  //     console.log("devToolsStatusdevToolsStatus", devToolsStatus);
  //     // else setDevToolsStatus("close");
  //   };
  //   addListener(handleDevToolsStatusChange);
  //   launch();
  //   return () => {
  //     addListener(handleDevToolsStatusChange);
  //   };
  // }, []);

  if (
    generalSettings?.[GENERAL_SETTINGS.SITE_UNDER_MAINTENANCE]?.metaData?.isSiteUnderMaintenance &&
    (!generalSettings?.[GENERAL_SETTINGS.SITE_UNDER_MAINTENANCE]?.metaData?.ip ||
      !generalSettings?.[GENERAL_SETTINGS.SITE_UNDER_MAINTENANCE]?.metaData?.ip
        .split(",")
        .includes(geoData?.ip))
  )
    return (
      <>
        <div>Site Under Maintenance</div>;
      </>
    );

  if (
    generalSettings?.[GENERAL_SETTINGS.SITE_UNDER_MAINTENANCE]?.metaData?.isSiteUnderUpgrade &&
    (!generalSettings?.[GENERAL_SETTINGS.SITE_UNDER_MAINTENANCE]?.metaData?.ip ||
      !generalSettings?.[GENERAL_SETTINGS.SITE_UNDER_MAINTENANCE]?.metaData?.ip
        .split(",")
        .includes(geoData?.ip))
  )
    return (
      <>
        <div>Site Upgrading</div>;
      </>
    );

  return (
    <React.Fragment>
      <div className="app-wrapper">
        {/* {devToolsStatus == "close" ? <Route /> : <DifferentWarningComponent />} */}
        <Route />
      </div>
    </React.Fragment>
  );
}

export default App;
