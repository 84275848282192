import React from "react";
import { useSelector } from "react-redux";
import ProfileMenuMobile from "./ProfileMenuMobile";

const UserProfileMobile = () => {
  const { isLoggedIn } = useSelector((state) => ({
    isLoggedIn: state.User.isLoggedIn,
  }));

  return (
    <React.Fragment>{isLoggedIn ? <ProfileMenuMobile /> : null}</React.Fragment>
  );
};

export default UserProfileMobile;
