import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDefaultStakeButtonsApi,
  getIP as getIPAPI,
  getPaymentModeApi,
  updateStakeButtonsApi,
} from "../../helpers/apiHelper/apis";

export const getIP = createAsyncThunk("Common/getIP", async () => {
  return await getIPAPI();
});

export const getDefaultStakeButtons = createAsyncThunk(
  "common/stakeButton/getDefaultStakeButtons",
  async (data) => {
    const response = await getDefaultStakeButtonsApi(data);

    return response.data;
  }
);

export const updateStakeButtons = createAsyncThunk(
  "common/stakeButton/updateStakeButtons",
  async (data) => {
    const response = await updateStakeButtonsApi(data);

    return response.data;
  }
);

export const getPaymentMode = createAsyncThunk(
  "deposit/paymentMode/getPaymentMode",
  async () => {
    const response = await getPaymentModeApi();
    return response;
  }
);
