import { createSlice } from "@reduxjs/toolkit";
import {
  addBankDetailsAction,
  createWithdrawRequestAction,
  getBankDetails,
  getBets,
  getWithdrawRequestData,
  loginViaUsernameAction,
  logoutAction,
  resetPasswordAction,
  getBankNames,
  getUserProfile,
  cancelWithdrawRequest,
  getUserData,
  editBankDetailsAction,
  resetNewUserPasswordAction,
} from "./action";
import { setAuthorization } from "../../helpers/apiHelper";
import { toast } from "react-toastify";
import { DEMO_USER, USER_TYPE } from "../../helpers/constants";

const d = localStorage.getItem("authUser");
let userDetails = {};
let userType = "";
if (d) {
  userDetails = JSON.parse(d);
  if (userDetails.username === DEMO_USER.USERNAME) userType = USER_TYPE.DEMO;
  else userType = USER_TYPE.REGULAR;
}
const initialState = {
  User: userDetails,
  userType: userType,
  isLoggedIn: "accessToken" in userDetails,
  isNewUser: false,
  otpSent: false,

  bets: { items: [], total: 0 },
  bankDetails: [],
  bankNames: [],
  withDrawPagination: {
    totalRows: 0,
    page: 1,
    limit: 10,
    sortOrder: "desc",
    sortField: "createdAt",
  },
  withdrawRequestData: {},
  resetPasswordData: {
    errorMsg: "",
    loading: false,
    error: false,
  },
};

export const User = createSlice({
  name: "User",
  initialState,
  reducers: {
    setUserType: function (state, action) {
      state.userType = action.payload;
    },
    setPagination: function (state, action) {
      state.withDrawPagination = {
        ...state.withDrawPagination,
        [action.payload.field]: action.payload.value,
      };
    },
    setNewUserStatus: function (state, action) {
      state.isNewUser = action.payload;
    },
  },
  extraReducers: (b) => {
    b.addCase(resetPasswordAction.pending, (state) => {
      state.resetPasswordData.loading = true;
      state.resetPasswordData.error = false;
    })
      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        state.resetPasswordData.loading = false;
        toast.success("Password Reset Successfully Done!!");
      })
      .addCase(resetPasswordAction.rejected, (state, action) => {
        state.resetPasswordData.loading = false;
        toast.error(
          action.error.message
            ? action.error.message
            : "Unable to place Reset the Password"
        );
      });
    b.addCase(resetNewUserPasswordAction.fulfilled, (state, action) => {
      // state.resetPasswordData.loading = false;
      toast.success("Password has been successfully reset!");
    }).addCase(resetNewUserPasswordAction.rejected, (state, action) => {
      // state.resetPasswordData.loading = false;
      toast.error(
        action.error.message
          ? action.error.message
          : "Unable to Reset the Password"
      );
    });
    b.addCase(getUserData.fulfilled, (state, action) => {
      state.User = action.payload;
    });
    b.addCase(loginViaUsernameAction.fulfilled, (state, action) => {
      if (action.payload) {
        setAuthorization(action.payload.accessToken, true);
      }
      if (action.payload?.isNewUser) {
        return {
          ...state,
          isNewUser: true,
        };
      }
      if (!action.payload?.isNewUser && action.payload) {
        state.User = action.payload;
        state.isLoggedIn = true;
        state.isNewUser = false;
        const jsonString = JSON.stringify(action.payload);
        localStorage.setItem("authUser", jsonString);
        localStorage.setItem("refreshCounter", 3);
      }
    })
      .addCase(loginViaUsernameAction.rejected, (state, action) => {
        toast.error(
          action?.error?.message ? action?.error?.message : "Unable to login"
        );
        state.User = {};
        state.isLoggedIn = false;
      })
      .addCase(logoutAction.fulfilled, (state) => {
        state.User = {};
        state.isLoggedIn = false;
        localStorage.removeItem("authUser");
      })

      .addCase(getBets.fulfilled, (state, action) => {
        state.bets = action.payload.data;
      })
      .addCase(getBankDetails.fulfilled, (state, action) => {
        if (action?.payload) {
          state.bankDetails = action.payload;
        }
      })
      .addCase(addBankDetailsAction.fulfilled, (state, action) => {
        state.bankDetails = [...state.bankDetails, action.payload.data];
        toast.success("Bank details added successfully");
      })
      .addCase(addBankDetailsAction.rejected, (state, action) => {
        if (action?.error?.message) {
          toast.error(action?.error?.message);
        } else {
          toast.error("Error occured");
        }
      })
      .addCase(editBankDetailsAction.fulfilled, (state, action) => {
        toast.success("Bank details update successfully");
      })
      .addCase(editBankDetailsAction.rejected, (state, action) => {
        toast.error(
          action?.error?.message ? action?.error?.message : "Error occured"
        );
      })
      .addCase(getBankNames.fulfilled, (state, action) => {
        state.bankNames = action.payload.data;
      })
      .addCase(cancelWithdrawRequest.fulfilled, (state, action) => {
        toast.success("Withdraw request cancelled.")
      })
      .addCase(cancelWithdrawRequest.rejected, (state, action) => {
        toast.error(
          action?.error?.message ? action?.error?.message : "Error occured"
        );
      })
      .addCase(createWithdrawRequestAction.fulfilled, (state, action) => {
        const updatedDetails =
          state.withDrawPagination.limit >
          state.withdrawRequestData.details?.length
            ? [...state.withdrawRequestData.details, action.payload.data[0]]
            : state.withdrawRequestData.details;
        toast.success("Withdraw Request Raised successfully");

        return {
          ...state,
          withdrawRequestData: {
            ...state.withdrawRequestData,
            total: state.withdrawRequestData.total + 1,
            details: updatedDetails,
          },
        };
      })
      .addCase(createWithdrawRequestAction.rejected, (state, action) => {
        toast.error(action?.error ? action?.error?.message : "Error occured");
      })
      .addCase(getWithdrawRequestData.fulfilled, (state, action) => {
        if (action?.payload) {
          state.withdrawRequestData = action.payload;
        }
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        return {
          ...state,
          User: action.payload,
        };
      });
  },
});

export const { setUserType, setPagination, setNewUserStatus } = User.actions;

export default User.reducer;
