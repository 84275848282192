import { createSlice } from "@reduxjs/toolkit";
import { getLimitSetting, getMarketOdds, getMarketsByEventId } from "./action";

const initialState = {
  data: {},
  event: {},
  limitSetting: {},
};

export const Market = createSlice({
  name: "Market",
  initialState,
  reducers: {
    setMarketData: (state, action) => {
      state.data[action.payload.subscribedMarketID] = action.payload;
    },
  },
  extraReducers: (b) => {
    b.addCase(getMarketsByEventId.fulfilled, (state, action) => {
      state.event = action.payload.data;
    }).addCase(getMarketsByEventId.rejected, (state, action) => {
      console.error(action.type, action.error);
    });
    b.addCase(getMarketOdds.fulfilled, (state, action) => {
      if (action.payload && action.payload.length) {
        for (let x = 0; x < action.payload.length; x++)
          state.data[action.payload[x].subscribedMarketID] = action.payload[x];
      }
    });
    b.addCase(getLimitSetting.fulfilled, (state, action) => {
      state.limitSetting = action.payload;
    });
  },
});

export const { setMarketData } = Market.actions;

export default Market.reducer;
