import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMatchesFilterApi,
} from '../../helpers/apiHelper/apis';

import { toast } from 'react-toastify';

export const getMatchesFilter = createAsyncThunk('Filter/getMatchesFilter', async () => {
  const response = await getMatchesFilterApi();
  return response.data;
});

