import { createSlice } from "@reduxjs/toolkit";
import { getPlacedBets, placeBetAction } from "./action";
import { toast } from "react-toastify";
import _ from "underscore";

const initialState = {
  bets: [],
  betsPL: [],
  betsCount: 0,
};

export const Bet = createSlice({
  name: "Bet",
  initialState,
  reducers: {
    clearPlacesBets: (state, action) => {
      state.bets = [];
    },
  },
  extraReducers: (b) => {
    b.addCase(placeBetAction.fulfilled, (state, action) => {
      toast.success("Bet Placed Successfully !!");
    }).addCase(placeBetAction.rejected, (state, action) => {
      toast.error(action.error.message ? action.error.message : "Unable to place Bet");
    });

    b.addCase(getPlacedBets.fulfilled, (state, action) => {
      state.betsPL = action.payload?.placedBetsPL;
      state.bets = action.payload?.placedBets;
      if (action.payload?.placedBets) {
        state.betsCount = _.reduce(
          action.payload?.placedBets,
          (memo, itm) => {
            return memo + itm.data.length;
          },
          0
        );
      }
    });
  },
});

export const { clearPlacesBets } = Bet.actions;
export default Bet.reducer;
