import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getGeneralSettingsApi,
  siteMaintenanceSettingApi,
  casinoSettingApi,
} from "../../helpers/apiHelper/apis";

export const getGeneralSettings = createAsyncThunk(
  "market/getGeneralSettings",
  async () => {
    const response = await getGeneralSettingsApi();
    return response.data;
  }
);

export const siteMaintenanceSetting = createAsyncThunk(
  "market/siteMaintenanceSetting",
  async () => {
    const response = await siteMaintenanceSettingApi();
    return response.data;
  }
);

export const casinoSettings = createAsyncThunk(
  "market/casinoSetting",
  async () => {
    const response = await casinoSettingApi();
    return response.data;
  }
);
