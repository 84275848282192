import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from "reactstrap";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { getPlacedBets } from "../../store/actions";
import { clearPlacesBets } from "../../store/bet/reducer";
import BetTabComponent from "./BetTabComponent";

const BetlistComponent = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const { event, bets } = useSelector((state) => ({
    bets: state.Bet.bets,
    event: state.Market.event?.event,
  }));

  useEffect(() => {
    if (params.eventID) dispatch(getPlacedBets(params.eventID));
    else dispatch(clearPlacesBets());
  }, [params.eventID]);

  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="sidebanner-tabs box-bg-sidebar mb-20">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: currentActiveTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Betlist
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="1">
            <Row>
              <BetTabComponent />
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </React.Fragment>
  );
};

export default BetlistComponent;
