import React, { useCallback, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Input,
  Label,
  FormFeedback,
  Alert,
  Spinner,
  Modal,
  ModalBody,
  CardTitle,
} from "reactstrap";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordAction } from "../../../store/user/action";
import CrossIcon from "../../../assets/svg/CrossIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { color } from "echarts";

const ResetPasswordModal = ({ resetModal, setResetModal }) => {
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);
  const dispatch = useDispatch();

  const { resetPasswordData } = useSelector((state) => ({
    resetPasswordData: state.User.resetPasswordData,
  }));

  const toggle = () => {
    setResetModal((prev) => !prev);
    validation.resetForm();
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required")
        .notOneOf(
          [Yup.ref("oldPassword")],
          "New password must not be equal to old password"
        ),
      confirmPassword: Yup.string()
        .when("newPassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            "Both password need to be the same"
          ),
        })
        .required("Confirm Password Required"),
    }),
    onSubmit: (values) => {
      dispatch(resetPasswordAction(values))
        .then(() => {
          toggle();
        })
        .catch((error) => {
          console.log(error);
          toggle();
        });
      validation.resetForm();
    },
  });

  return (
    <Modal id="loginModal" isOpen={resetModal} toggle={toggle} centered>
      <ModalBody>
        <Button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={toggle}
        >
        </Button>

        <Container>
          <Row>
            <Col>
              <Card className="mt-4 border-0">
                <CardBody className="p-0">
                  <CardTitle tag="h4" className="fw-bold">
                    Create new password
                  </CardTitle>

                  <div className="p-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="/auth-signin-basic"
                    >
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="old-password-input"
                        >
                          Old Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            type={oldPasswordShow ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter old password"
                            id="old-password-input"
                            name="oldPassword"
                            value={validation.values.oldPassword}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.oldPassword &&
                              validation.touched.oldPassword
                                ? true
                                : false
                            }
                          />
                          {validation.errors.oldPassword &&
                          validation.touched.oldPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.oldPassword}
                            </FormFeedback>
                          ) : null}
                          <button
                            className="btn border-0 show-hide-pass position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            onClick={() => setOldPasswordShow(!oldPasswordShow)}
                          >
                            {oldPasswordShow ? (
                              <FontAwesomeIcon icon={faEye} />
                            ) : (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="newPassword-input"
                        >
                          New Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input
                            type={passwordShow ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter password"
                            id="newPassword-input"
                            name="newPassword"
                            value={validation.values.newPassword}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.newPassword &&
                              validation.touched.newPassword
                                ? true
                                : false
                            }
                          />
                          {validation.errors.newPassword &&
                          validation.touched.newPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.newPassword}
                            </FormFeedback>
                          ) : null}
                          <button
                            className="btn border-0 show-hide-pass position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            {passwordShow ? (
                              <FontAwesomeIcon icon={faEye} />
                            ) : (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="confirm-password-input"
                        >
                          Confirm Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            type={confrimPasswordShow ? "text" : "password"}
                            className="form-control "
                            placeholder="Confirm password"
                            id="confirm-password-input"
                            name="confirmPassword"
                            value={validation.values.confirmPassword}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.confirmPassword &&
                              validation.touched.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {validation.errors.confirmPassword &&
                          validation.touched.confirmPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          ) : null}
                          <button
                            className="btn border-0 show-hide-pass position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            onClick={() =>
                              setConfrimPasswordShow(!confrimPasswordShow)
                            }
                          >
                            {confrimPasswordShow ? (
                              <FontAwesomeIcon icon={faEye} />
                            ) : (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="mt-4">
                        <Button
                          disabled={
                            resetPasswordData.error
                              ? null
                              : resetPasswordData.loading
                              ? true
                              : false
                          }
                          color="primary"
                          className="btn btn-primary active w-100"
                          type="submit"
                        >
                          {resetPasswordData.error ? null : resetPasswordData.loading ? (
                            <Spinner size="sm" className="me-2">
                              Loading...
                            </Spinner>
                          ) : null}
                          Reset Password
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ResetPasswordModal;
