import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Input,
  ListGroup,
  ListGroupItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

// Icons
import WalletIcon from "../../assets/svg/WalletIcon";
import { getAccountBalance } from "../../store/actions";
import { USER_TYPE } from "../../helpers/constants";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { formatDateRelativeToNow } from "../../helpers/methods/formatDate";
import { roundToTwoDecimalPlaces } from "../../helpers/constants/functionConstants";

const DepostiMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const { balance, withdraw, exposure, userType, sportEvents } = useSelector(
    (state) => ({
      balance: state.Account.balance,
      withdraw: state.Account.withdraw,
      exposure: state.Account.exposure,
      userType: state.User.userType,
      paymentModes: state.Common.paymentMode,
      sportEvents: state.Sport.sportEvents,
    })
  );

  const [searchbar, setSearchBar] = useState(false);
  const [lististVisible, setListVisible] = useState(false);
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedText, setSelecteText] = useState("");

  const getBalance = () => dispatch(getAccountBalance());

  useEffect(() => {
    getBalance();
  }, []);

  const searchText = useCallback((query) => {
    const filteredData = options?.filter((item) => {
      return item?.eventName
        ? item?.eventName.toLowerCase().toString().includes(query.toLowerCase())
        : true;
    });
    setFilteredOptions(filteredData);
  });

  useEffect(() => {
    searchText(selectedText);
  }, [selectedText, options]);

  useEffect(() => {
    let arr = [];
    const result = Object.keys(sportEvents).map((key) => sportEvents[key]);
    for (let i = 0; i < result.length; i++) {
      let item = result[i];
      for (let j = 0; j < item.length; j++) {
        arr.push(item[j]);
      }
    }
    setOptions(arr);
  }, [sportEvents]);

  const handleChange = (e) => {
    e.preventDefault();
    setSelecteText(e.target.value);
    if (e.target.value) setListVisible(true);
  };
  const handleBlur = () => {
    const delayFn = setTimeout(() => {
      setListVisible(false);
    }, 200);
    return () => clearTimeout(delayFn);
  };

  return (
    <React.Fragment>
      <div
        style={{ cursor: "pointer" }}
        className="text-white me-2 d-flex align-items-center gap-2 position-relative"
      >
        {searchbar && (
          <div className="header-search">
            <Input
              placeholder="All Events"
              onChange={handleChange}
              value={selectedText}
              onBlur={handleBlur}
            />
            {lististVisible && selectedText && (
              <div>
                <Card
                  className="position-absolute top-5 mt-3"
                  style={{
                    minWidth: "28rem",
                    maxHeight: "25rem",
                    overflowY: "scroll",
                  }}
                >
                  <ListGroup>
                    {filteredOptions && filteredOptions.length ? (
                      filteredOptions.map((item) => (
                        <Link
                          className="text-decoration-none"
                          key={item._id}
                          to={`/event/${item?._id}/${encodeURI(
                            item?.compititionName
                          )}/${encodeURI(item?.eventName)}`}
                        >
                          <ListGroupItem className="d-flex align-items-center justify-content-between pb-0">
                            <div>
                              <p className="fw-bold mb-0">{item.eventType}</p>
                              <p className="fw-light p-0 mb-0">
                                {item.eventName}
                              </p>
                            </div>

                            <p
                              style={{ fontSize: ".8rem" }}
                              className="fw-light mb-0"
                            >
                              {formatDateRelativeToNow(item.eventDate).date}
                              <br />
                              {formatDateRelativeToNow(item.eventDate).time}
                            </p>
                          </ListGroupItem>
                        </Link>
                      ))
                    ) : (
                      <ListGroupItem
                        style={{ fontSize: ".8rem" }}
                        className="fw-bold"
                      >
                        No Data Found
                      </ListGroupItem>
                    )}
                  </ListGroup>
                </Card>
              </div>
            )}
          </div>
        )}
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => setSearchBar((prev) => !prev)}
        />
      </div>

      <Dropdown
        isOpen={dropdownOpen}
        className="balanceOuter"
        data-bs-theme="dark"
        toggle={toggle}
      >
        <DropdownToggle caret className="btn btn-sm btn-dark">
          <WalletIcon />
          <span className="text-white">
            {roundToTwoDecimalPlaces(balance - Math.abs(exposure))}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <div className="withDrawal">
            <p>
              Withdrawal: <span>{roundToTwoDecimalPlaces(withdraw)}</span>
            </p>
            <p className="mb-0">
              Exposure: <span>{Math.abs(exposure)}</span>
            </p>
          </div>
        </DropdownMenu>
      </Dropdown>
      <Button
        className="theme-btn ms-2 depositBtn d-none d-md-inline"
        onClick={() => navigate("/user/deposit")}
      >
        Deposit
      </Button>
      <Button
        className="theme-btn ms-2 withdrawalBtn d-none d-md-inline"
        onClick={() => navigate("/account/account-withdraw")}
      >
        Withdrawal
      </Button>
    </React.Fragment>
  );
};

export default DepostiMenu;
