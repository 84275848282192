import { APIHelper } from "./index";
import * as url from "./url_helper";

export const getIP = () => APIHelper.get(url.GET_IP);
export const postLogin = (data) => APIHelper.post(url.AUTH_LOGIN, data);
export const postRefreshToken = (data) =>
  APIHelper.post(url.AUTH_REFERSH_TOKEN, data);

// SPORT Related
export const loginApi = (data) => APIHelper.post(url.LOGIN, data);
export const getUserDataApi = () => APIHelper.get(url.ME);
export const resetUserPassword = (resetPasswordData) =>
  APIHelper.put(url.RESET_PASSWORD, resetPasswordData);
export const getAllEvents = () => APIHelper.get(url.SPORTS_ALL_EVENT);
export const getSportTypeList = () => APIHelper.get(url.SPORTS_LIST);
export const getIsBlocked = () => APIHelper.get(url.IS_IP_BLOCKED);
export const sendOtpApi = (data) => APIHelper.post(url.SEND_OTP, data);
export const verifyOtpApi = (data) => APIHelper.post(url.VERIFY_OTP, data);
export const usernameExistsApi = (data) =>
  APIHelper.post(url.USERNAME_EXISTS, data);
export const mobileExistsApi = (data) =>
  APIHelper.post(url.MOBILE_EXISTS, data);
export const getMarketByEventIdApi = (data) =>
  APIHelper.get(url.MARKET_BY_EVENT_ID(data));
export const getEventsByEventType = (data) =>
  APIHelper.get(url.EVENT_BY_EVENTTYPE_ID(data));

export const getSportsBook = () => APIHelper.get(url.SPORTS_BOOK);
export const getMarketOddsAPI = (data) =>
  APIHelper.post(url.GET_MARKET_ODDS, data);
export const getRulesApi = () => APIHelper.get(url.GET_EVENT_TYPES_RULES);
export const getSiteRulesAPI = () => APIHelper.get(url.GET_SITE_RULES);
export const getLimitSetting = (data) =>
  APIHelper.get(url.MARKET_LIMIT_SETTING(data));

// Wallet API
export const getBalance = () => APIHelper.get(url.ACCOUNT_BALANCE);
export const manualDeposit = (data) =>
  APIHelper.post(url.WALLET_DEPOSIT_MANUAL, data);
export const getStatement = (data) =>
  APIHelper.post(url.ACCOUNT_STATEMENT, data);
export const getPaymentModeApi = () => APIHelper.get(url.GET_PAYMENT_MODE);

// Bet Related APIs
export const placeBet = (data) => APIHelper.post(url.POST_PLACE_BET, data);
export const placedBets = (data) => APIHelper.get(url.GET_PLACED_BETS(data));
export const getBetsApi = (params) => APIHelper.get(url.GET_USER_BETS, params);
export const getMatchesFilterApi = () => APIHelper.get(url.GET_MATCHES_FILTER);

// Casions
export const getCasinoList = () => APIHelper.get(url.GET_CASINOS);
export const getCasinoEvent = (params) =>
  APIHelper.get(url.GET_CASINO_EVENT(params));
export const getCasinoProvidersList = () =>
  APIHelper.get(url.GET_CASINO_PROVIDERS);
export const getCasinoGameTypeList = () =>
  APIHelper.get(url.GET_CASINO_GAME_TYPES);

// bank details related

export const getBankDetailsApi = () => {
  return APIHelper.get(url.GET_USER_BANK_DETAILS);
};

export const addBankDetails = (data) =>
  APIHelper.post(url.ADD_BANK_DETAILS, data);

export const editBankDetails = (data, id) =>
  APIHelper.put(url.EDIT_BANK_DETAILS(id), data);

export const getBankNamesApi = () => APIHelper.get(url.GET_BANK_NAMES);

export const createWithdrawRequest = (data) =>
  APIHelper.post(url.CREATE_WITHDRAW_REQUEST, data);

export const getWithdrawRequestApi = (params) => {
  return APIHelper.get(url.GET_WITHDRAW_REQUEST_DATA, params);
};

export const cancelWithdrawRequestApi = (params) => {
  return APIHelper.get(url.CANCEL_WITHDRAW_REQUEST_DATA(params));
};

export const getTransactionHistoryApi = (payload) => {
  return APIHelper.post(url.GET_TRANSACTION_HISTORY, payload);
};
//stake Buttons related
export const getDefaultStakeButtonsApi = (data) =>
  APIHelper.get(url.GET_DEFAULT_STAKE_BUTTONS, data);

export const updateStakeButtonsApi = (data) =>
  APIHelper.put(url.UPDATE_DEFAULT_STAKE_BUTTONS, data);

export const getUserProfileApi = () => APIHelper.get(url.GET_USER_PROFILE);

export const getGeneralSettingsApi = () =>
  APIHelper.get(url.GET_GENERAL_SETTINGS);
export const siteMaintenanceSettingApi = () =>
  APIHelper.get(url.SITE_MAINTANANCE_SETTINGS);
export const casinoSettingApi = () => APIHelper.get(url.CASINO_SETTINGS);
