import { createSlice } from "@reduxjs/toolkit";
import {
  getAccountBalance,
  manualDepositAction,
  getStatementList,
  getTransactionHistory,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  balance: 0.0,
  withdraw: 0.0,
  exposure: 0.0,
  // accountStatement: {
  //   statement: [],
  //   statementCount: 0,
  // },
  accountStatement: [],

  transactionHistory: [],
};

export const Account = createSlice({
  name: "Account",
  initialState,
  reducers: {
    resetAccountHistory: function (state, action) {
      state.accountStatement = [];
    },
  },
  extraReducers: (b) => {
    b.addCase(manualDepositAction.fulfilled, (state, action) => {
      if (action?.payload?.data) {
        toast.success("Deposit request created successfully.");
      }
    }).addCase(manualDepositAction.rejected, (state, action) => {
      if (action?.error?.message) {
        toast.error(action.error.message);
      }
    });
    b.addCase(getAccountBalance.fulfilled, (state, action) => {
      state.balance = action.payload?.balance;
      state.withdraw = action.payload?.withdraw;
      state.exposure = action.payload?.exposure;
    });
    b.addCase(getStatementList.fulfilled, (state, action) => {
      state.accountStatement = action.payload;
    });
    b.addCase(getTransactionHistory.fulfilled, (state, action) => {
      if (action.payload) {
        state.transactionHistory = action.payload;
      }
    });
  },
});
export const { resetAccountHistory } = Account.actions;

export default Account.reducer;
