import { createAsyncThunk } from "@reduxjs/toolkit";

export const changeLoader = createAsyncThunk("Layout/changeLoader", (value) => {
  return value;
});

// export const changeLayout = (layout) => ({
//   type: CHANGE_LAYOUT,
//   payload: layout,
// });
