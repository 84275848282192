import React from 'react';
import DOMPurify from 'dompurify';

function RawHtmlComponent(props) {
  // If you're sure the content is safe or comes from a trusted source, you might skip sanitizing.
  const sanitizedHtml = DOMPurify.sanitize(props.htmlString);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}

export default RawHtmlComponent;
