import moment from "moment-timezone";

const formatDateRelativeToNow = (inputDateStr) => {
  let inputDateTemp =
    typeof inputDateStr === "string" && !isNaN(inputDateStr)
      ? moment.unix(inputDateStr)
      : inputDateStr;
  const currentTimezone = moment.tz.guess();
  const inputDate = moment.tz(inputDateTemp, currentTimezone);
  const currentDate = moment.tz(currentTimezone);

  // Compare the dates.
  if (inputDate.isSame(currentDate, "day")) {
    // If it's today.
    return { date: "Today", time: inputDate.format("hh:mm:ss A") };
  } else if (inputDate.isSame(currentDate.clone().add(1, "days"), "day")) {
    // If it's tomorrow.
    return { date: "Tomorrow", time: inputDate.format("hh:mm:ss A") };
  } else {
    // Otherwise, format as "DD-MM HH:mm".
    return {
      date: inputDate.format("DD-MM-YYYY"),
      time: inputDate.format("hh:mm:ss A"),
    };
  }
};

const isWithinDateRange = (dateToCompare, startDate, endDate) => {
  let inputDateTemp =
    typeof dateToCompare === "string" && !isNaN(dateToCompare)
      ? moment.unix(dateToCompare)
      : dateToCompare;
  const currentTimezone = moment.tz.guess();
  const inputDate = moment.tz(inputDateTemp, currentTimezone);
  startDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
    0,
    0,
    0,
    0
  );
  endDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate(),
    23,
    59,
    59,
    999
  );

  return (
    inputDate.isSameOrAfter(startDate) && inputDate.isSameOrBefore(endDate)
  );
};

const formatDateWithTime = (inputDateStr) => {
  const currentTimezone = moment.tz.guess();
  const inputDate = moment.tz(inputDateStr, currentTimezone);
  return inputDate.format("DD-MM-YYYY hh:mm:ss");
};

const formatDateWithTimeAMPM = (inputDateStr) => {
  const currentTimezone = moment.tz.guess();
  const inputDate = moment.tz(inputDateStr, currentTimezone);
  return inputDate.format("MM-DD-YYYY hh:mm A");
};

function isWithinTimeFromNow(inputTimeLimit, dateToCompare) {
  const currentTimezone = moment.tz.guess();

  let currentDateTime = moment();
  let tempDateToCompare =
    typeof dateToCompare === "string" && !isNaN(dateToCompare)
      ? moment.unix(dateToCompare)
      : dateToCompare;
  if (inputTimeLimit === "TODAY") {
    return moment(tempDateToCompare)
      .tz(currentTimezone)
      .isSame(currentDateTime, "day"); //filter for today
  }

  if (inputTimeLimit === "TOMORROW") {
    //filter for tomorrow
    return moment(tempDateToCompare)
      .tz(currentTimezone)
      .isSame(currentDateTime.clone().add(1, "days"), "day");
  }

  let currentDateTimeWithAddedHourUTC = currentDateTime
    .clone()
    .add(inputTimeLimit, "hours");

  dateToCompare = moment(tempDateToCompare).tz(currentTimezone).format("");

  return (
    moment(dateToCompare).isSameOrBefore(currentDateTimeWithAddedHourUTC) &&
    moment(dateToCompare).isSameOrAfter(currentDateTime)
  );
}

export {
  formatDateRelativeToNow,
  formatDateWithTime,
  formatDateWithTimeAMPM,
  isWithinTimeFromNow,
  isWithinDateRange,
};
