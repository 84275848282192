export const OTP_MODAL = "OTP_MODAL";
export const FORGOT_PASS_MODAL = "FORGOT_PASS_MODAL";

export const marketTypeName = (bettingType) => {
  switch (bettingType) {
    case "MATCH_ODDS":
      return "Market";
    case "ODDS":
      return "Odds";
    case "ODDEVEN":
      return "Odd Even";
    case "OTHER":
      return "Other";
    case "SESSION":
      return "Session";
    case "LINE":
      return "Line";
    case "KHADO":
      return "Khado";
    case "BALLBYBALL":
      return "Ball By Ball";
    case "METER":
      return "Meter";
    default:
      return bettingType;
  }
};

export const BANKS = [
  { bankId: "1", bankName: "UBI" },
  { bankId: "2", bankName: "SBI" },
  { bankId: "3", bankName: "HDFC" },
];

export const GENERAL_SETTINGS = {
  ONLINE_PAYMENT: "onlinePayment",
  MARKUP_TEXT: "markupText",
  SOCIAL_MEDIA: "socialMedia",
  USER_TYPE: "userType",
  MARKET_SUSPENDED: "marketSuspended",
  SITE_UNDER_MAINTENANCE: "siteUnderMaintenance",
  CASINO: "casino",
};

export const WITHDRAWSTATUS = {
  0: "Pending",
  1: "Approved",
  2: "Verified",
  3: "Declined",
  5: "Cancelled",
};

export const DEMO_USER = {
  USERNAME: "demouser",
  PASSWORD: "demoUser@#123",
};

export const USER_TYPE = {
  DEMO: "demo",
  REGULAR: "regular",
};

export const filters = [1, 3, 6, 12, "TODAY", "TOMORROW"];

export const DEPOSIT_WITHDRAW_STATUS = {
  0: "PENDING",
  1: "COMPLETED",
  2: "APPROVED",
  3: "IN_PROGRESS",
  4: "DECLINED",
  5: "FAILED",
  6: "CANCELLED",
};

export const DEPOSIT_STATUS = {
  0: "PENDING",
  1: "APPROVED",
  2: "APPROVED_WITH_PRICE_CHANGE",
  3: "DECLINED",
  4: "MANUAL_APPROVED",
};

export const WITHDRAW_STATUS = {
  0: "PENDING",
  1: "APPROVED",
  2: "VERIFIED",
  3: "DECLINED",
};

export const TRANSACTION_TYPE = {
  1: "DEPOSIT",
  2: "WITHDRAW",
  3: "WIN",
  4: "LOSE",
  5: "MANUAL_DEPOSIT",
  6: "MANUAL_WITHDRAW",
  7: "REFUND",
  11: "CASINO_DEPOSIT",
  12: "CASINO_WITHDRAW",
  13: "CASINO_ROLLBACK",
};

export const BET_RESULT = {
  OPEN: "OPEN",
  WIN: "WIN",
  LOSE: "LOSE",
  REFUND: "REFUND",
  ABUNDANT: "ABUNDANT",
};
