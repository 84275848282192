const EditIcon = () => {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_318_7490)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.78125 7.5C0.78125 3.78934 3.78934 0.78125 7.5 0.78125C7.75888 0.78125 7.96875 0.991116 7.96875 1.25C7.96875 1.50888 7.75888 1.71875 7.5 1.71875C4.3071 1.71875 1.71875 4.3071 1.71875 7.5C1.71875 10.6929 4.3071 13.2812 7.5 13.2812C10.6929 13.2812 13.2812 10.6929 13.2812 7.5C13.2812 7.24112 13.4911 7.03125 13.75 7.03125C14.0089 7.03125 14.2187 7.24112 14.2187 7.5C14.2187 11.2107 11.2107 14.2187 7.5 14.2187C3.78934 14.2187 0.78125 11.2107 0.78125 7.5ZM10.4816 1.42245C11.3365 0.567518 12.7226 0.567518 13.5776 1.42245C14.4325 2.27738 14.4325 3.66349 13.5776 4.51842L9.42249 8.67348C9.19044 8.90556 9.04508 9.05094 8.88286 9.17747C8.6918 9.32649 8.48508 9.45426 8.26634 9.5585C8.08063 9.64701 7.88559 9.712 7.57424 9.81576L5.75891 10.4209C5.42376 10.5326 5.05425 10.4454 4.80444 10.1956C4.55464 9.94574 4.46741 9.57624 4.57913 9.24109L5.18423 7.42577C5.28799 7.11441 5.35299 6.91937 5.4415 6.73366C5.54574 6.51492 5.67351 6.3082 5.82253 6.11714C5.94905 5.95492 6.09444 5.80955 6.32653 5.5775L10.4816 1.42245ZM12.9146 2.08536C12.4258 1.59655 11.6333 1.59655 11.1445 2.08536L10.9091 2.32075C10.9233 2.38066 10.9431 2.45204 10.9708 2.53166C11.0603 2.78983 11.2298 3.12982 11.55 3.45C11.8702 3.77017 12.2102 3.93966 12.4683 4.02923C12.548 4.05685 12.6193 4.0767 12.6793 4.09089L12.9146 3.8555C13.4035 3.36669 13.4035 2.57417 12.9146 2.08536ZM11.9407 4.82943C11.6182 4.69075 11.2426 4.46841 10.8871 4.11291C10.5316 3.7574 10.3093 3.38176 10.1706 3.05928L7.01094 6.21891C6.75062 6.47923 6.64853 6.58247 6.56176 6.69372C6.45461 6.83109 6.36275 6.97972 6.2878 7.13699C6.2271 7.26435 6.18042 7.40183 6.064 7.75109L5.79407 8.56089L6.4391 9.20593L7.24891 8.93599C7.59817 8.81958 7.73565 8.77289 7.86301 8.7122C8.02028 8.63725 8.16891 8.54539 8.30628 8.43824C8.41753 8.35147 8.52076 8.24938 8.78109 7.98906L11.9407 4.82943Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_318_7490'>
          <rect width='15' height='15' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
