import { createSlice } from "@reduxjs/toolkit";
import {
  casinoSettings,
  getGeneralSettings,
  siteMaintenanceSetting,
} from "./action";
import { GENERAL_SETTINGS } from "../../helpers/constants";

const initialState = {
  generalSettings: {},
};

const GeneralSettings = createSlice({
  name: "Settings",
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getGeneralSettings.fulfilled, (state, action) => {
      const mapSettings = {};
      action.payload.map((item) => {
        mapSettings[item.name] = item;
      });

      state.generalSettings = mapSettings;
    });

    b.addCase(siteMaintenanceSetting.fulfilled, (state, action) => {
      state.generalSettings[GENERAL_SETTINGS.SITE_UNDER_MAINTENANCE] =
        action.payload;
    });
    b.addCase(casinoSettings.fulfilled, (state, action) => {
      state.generalSettings[GENERAL_SETTINGS.CASINO] = action.payload;
    });
  },
});

export default GeneralSettings.reducer;
