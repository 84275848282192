import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import ProfileMenu from "./ProfileMenu";
import DepostiMenu from "./DepostiMenu";

const ProfileSection = () => {
  const { isLoggedIn } = useSelector((state) => ({
    isLoggedIn: state.User.isLoggedIn,
  }));

  useEffect(() => {
    if (!isLoggedIn) localStorage.removeItem("authUser");
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      <div className="nav-right d-inline-flex align-items-center">
        <div className="nav-settings-wrapper d-flex align-items-center d-nones">
          {/* Deposit Menu */}
          {isLoggedIn ? <DepostiMenu /> : null}

          {/* Profile Menu */}
          {isLoggedIn ? <ProfileMenu /> : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileSection;
