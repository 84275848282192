import React from "react";
import "./../../assets/scss/loader.css";
import LoaderIcon from "../../assets/svg/LoaderIcon";

const LoaderComponent = ({ isFullPage }) => {
  if (isFullPage)
    return (
      <div className="main-fader" responsive-height-comments="okay">
        <div className="loader_web">
          <LoaderIcon />
        </div>
      </div>
    );

  return (
    <div className="div-loader">
      <div className="loader"></div>
    </div>
  );
};

export default LoaderComponent;
