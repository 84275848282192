import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import socket from "../sockets/socket";
import { setMarketData } from "../store/market/reducer";
import classNames from "classnames";
import { toast } from "react-toastify";

const Mainnav = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();

  const { isLoggedIn, sportsTypes, isCasinoLock, generalSettings } =
    useSelector((state) => ({
      sportsTypes: state.Sport.sportsTypes,
      isLoggedIn: state.User.isLoggedIn,
      isCasinoLock: state.User.User?.isCasinoLock,
      generalSettings: state.Settings?.generalSettings,
    }));

  const [active, setActive] = useState("");

  const redirectToCasino = (type) => {
    if (isCasinoLock && type !== "/sport")
      toast.error(
        "You Don't Have Access to Casino, Please Contact your Administrator."
      );
    else {
      if (location.pathname !== type) navigate(type);
    }
  };

  useEffect(() => {
    socket.on("rate", (updatedEventData) => {
      dispatch(setMarketData(updatedEventData));
    });
    return () => {};
  }, [dispatch]);
  return (
    <React.Fragment>
      <ul className="mainnav d-flex d-lg-none">
        <li
          className={classNames("mainnav-item", {
            active: location.pathname === "/sport",
          })}
        >
          <div
            onClick={() => redirectToCasino("/sport")}
            className="mainnav-link "
          >
            Home
          </div>
        </li>
        {generalSettings?.casino?.metaData?.isInternationalCasinoActive && (
          <li
            className={classNames("mainnav-item", {
              active: location.pathname === "/casino",
            })}
          >
            <div
              onClick={() => redirectToCasino("/casino")}
              title="Casino"
              className="mainnav-link "
            >
              Casino
            </div>
          </li>
        )}
        {generalSettings?.casino?.metaData?.isIndianCasinoActive && (
          <li
            className={classNames("mainnav-item", {
              active: location.pathname === "/indian-casino",
            })}
          >
            <div
              onClick={() => redirectToCasino("/indian-casino")}
              title="Indian Casino"
              className="mainnav-link "
            >
              Indian Casino
            </div>
          </li>
        )}
        {sportsTypes.map((item, i) => {
          return (
            <li
              key={i}
              onClick={() => setActive(item._id)}
              className={classNames("mainnav-item", {
                active: location.pathname.includes(item._id),
              })}
            >
              <Link
                to={"/sport/" + item._id}
                title={item.name}
                className="mainnav-link"
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

export default Mainnav;
