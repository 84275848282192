import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginApi,
  getBetsApi,
  getBankDetailsApi,
  addBankDetails,
  createWithdrawRequest,
  getWithdrawRequestApi,
  resetUserPassword,
  getBankNamesApi,
  getIP,
  getRulesApi,
  getUserProfileApi,
  cancelWithdrawRequestApi,
  getUserDataApi,
  editBankDetails,
  getIsBlocked,
} from "../../helpers/apiHelper/apis";

export const resetPasswordAction = createAsyncThunk(
  "login/resetPassword",
  async (resetPasswordData) => {
    const response = await resetUserPassword(resetPasswordData);
    return response;
  }
);

export const resetNewUserPasswordAction = createAsyncThunk(
  "login/resetNewUserPasswordAction",
  async (resetPasswordData) => {
    const response = await resetUserPassword(resetPasswordData);
    return response;
  }
);
export const loginViaUsernameAction = createAsyncThunk(
  "User/login",
  async (data) => {
    const response = await loginApi(data);
    return response.data;
  }
);

export const getUserData = createAsyncThunk("User/userData", async () => {
  const res = await getUserDataApi();
  return res.data;
});
export const getIsIPBlocked = createAsyncThunk(
  "Common/isIPBlocked",
  async () => {
    return await getIsBlocked();
  }
);
export const logoutAction = createAsyncThunk("User/logout", async () => {
  return true;
});
export const getIPAction = createAsyncThunk("Common/getIP", async () => {
  return await getIP();
});

export const getBets = createAsyncThunk("User/bets/getBets", async (data) => {
  return await getBetsApi(data);
});

export const getBankDetails = createAsyncThunk(
  "User/bank-details/get-bank-details",
  async () => {
    const response = await getBankDetailsApi();

    return response.data;
  }
);

export const addBankDetailsAction = createAsyncThunk(
  "User/bank-details/add-bank-details",
  async (data) => {
    let res = await addBankDetails(data);

    return res;
  }
);

export const editBankDetailsAction = createAsyncThunk(
  "User/bank-details/edit-bank-details",
  async ({ data, id }) => {
    let response = await editBankDetails(data, id);
    return response.data;
  }
);

export const getBankNames = createAsyncThunk(
  "User/bank-names/get-bank-names",
  async () => await getBankNamesApi()
);

export const createWithdrawRequestAction = createAsyncThunk(
  "User/withdrawal/createWithdrawRequest",
  async (data) => {
    let result = await createWithdrawRequest(data);

    return result;
  }
);

export const getWithdrawRequestData = createAsyncThunk(
  "User/withdrawal/getWithDrawRequestData",
  async (data) => {
    const response = await getWithdrawRequestApi(data);

    return response.data;
  }
);

export const cancelWithdrawRequest = createAsyncThunk(
  "User/withdrawl/cancelWithdrawRequest",
  async (data) => {
    const response = await cancelWithdrawRequestApi(data);
    return response.data;
  }
);

export const getUserProfile = createAsyncThunk(
  "User/getUserProfile",
  async () => {
    const res = await getUserProfileApi();
    return res?.data;
  }
);
