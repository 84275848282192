import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";

import { USER_TYPE } from "../helpers/constants";
import { useNavigate } from "react-router-dom";

const DepositWithdraw = () => {
  const navigate = useNavigate();
  const { userType } = useSelector((state) => ({
    userType: state.User.userType,
  }));

  const deposit = () => {
    if (userType === USER_TYPE.REGULAR) navigate("/user/deposit");
  };
  const withdawal = () => {
    if (userType === USER_TYPE.REGULAR) navigate("/account/account-withdraw");
  };

  return (
    <div className="DepositWithdraw-bar d-flex d-md-none w-100">
      <div className="col">
        <button className="theme-btn depositBtn w-100" onClick={deposit}>
          Deposit
        </button>
      </div>
      <div className="col">
        <button
          className="theme-btn btn btn-danger withdrawBtn w-100"
          onClick={withdawal}
        >
          Withdraw
        </button>
      </div>
    </div>
  );
};

export default DepositWithdraw;
