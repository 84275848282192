import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

// Icons
import Avatar01 from "../../assets/images/Avatar.png";
import UserIcon from "./../../assets/svg/UserIcon";
import EditIcon from "../../assets/svg/EditIcon";
import UserProfileIcon from "../../assets/svg/UserProfileIcon";
import AccountStatementIcon from "../../assets/svg/AccountStatementIcon";
import WithdrawIcon from "../../assets/svg/WithdrawIcon";
import TransactionIcon from "../../assets/svg/TransactionIcon";
import BonusIcon from "../../assets/svg/BonusIcon";
import OpenBetIcon from "../../assets/svg/OpenBetIcon";
import SignOutIcon from "../../assets/svg/SignOutIcon";
import { logoutAction } from "../../store/actions";

const ProfileMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { User } = useSelector((state) => state);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => setIsProfileDropdown(!isProfileDropdown);

  return (
    <React.Fragment>
      <div className="header-drodwn d-inline-flex">
        <Dropdown
          isOpen={isProfileDropdown}
          toggle={toggleProfileDropdown}
          className="profile-dropdown"
        >
          <DropdownToggle
            tag="button"
            type="button"
            className="btn-no-bg nav-profile  align-items-center justify-content-center ms-2 dropdown-toggle"
          >
            <UserIcon />
          </DropdownToggle>
          <DropdownMenu className="p-10 dropdown-menu">
            <div className="drp-profile-img d-flex align-items-center flex-wrap">
              <figure className="m-0 profileimg-circle">
                <div className="profile-inner-circle">
                  <img src={Avatar01} alt="user" />
                </div>
                <span className="online-status"></span>
              </figure>
              <div className="profile-name ms-3">
                <p className="m-0">{User?.User?.username}</p>
              </div>
              <div className="edit-img ms-auto">
                <Link
                  to={"/account/user-profile"}
                  onClick={() => toggleProfileDropdown()}
                >
                  <EditIcon />
                </Link>
              </div>
            </div>
            <ul className="profile-nav-link m-0">
              <li>
                <Link
                  to={"/account/user-profile"}
                  onClick={() => toggleProfileDropdown()}
                >
                  <UserProfileIcon />
                  User Profile
                </Link>
              </li>
              <li>
                <Link
                  to={"/account/account-statement"}
                  onClick={() => toggleProfileDropdown()}
                >
                  <AccountStatementIcon />
                  Account Statement
                </Link>
              </li>
              <li>
                <Link
                  to={"/account/transaction-history"}
                  onClick={() => toggleProfileDropdown()}
                >
                  <TransactionIcon />
                  Transaction History
                </Link>
              </li>
              <li>
                <Link
                  to={"/account/stakebutton"}
                  onClick={() => toggleProfileDropdown()}
                >
                  <TransactionIcon />
                  Change Stake Button
                </Link>
              </li>
              {/* <li>
                <Link to={"/account/bonus"}>
                  <BonusIcon />
                  Bonus List
                </Link>
              </li> */}
              <li>
                <Link
                  to={"/account/open-bets"}
                  onClick={() => toggleProfileDropdown()}
                >
                  <OpenBetIcon />
                  Open Bets
                </Link>
              </li>
              <li>
                <Link
                  to={"/account/rules"}
                  onClick={() => toggleProfileDropdown()}
                >
                  <AccountStatementIcon />
                  Rules
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    dispatch(logoutAction());
                    navigate("/");
                  }}
                >
                  <SignOutIcon />
                  Sign Out
                </Link>
              </li>
            </ul>
          </DropdownMenu>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

export default ProfileMenu;
