import { createSlice } from "@reduxjs/toolkit";
import { changeLoader } from "./action";
//constants
const INIT_STATE = {
  themeMode: "dark",
  currentModal: "",
  prevModal: "",
  loader: false,
};

export const layoutSlice = createSlice({
  name: "Layout",
  initialState: INIT_STATE,
  reducers: {
    changeMode: (state, action) => {
      state.themeMode = action.payload;
    },
    setCurrentModal: (state, action) => {
      state.prevModal = state.currentModal;
      state.currentModal = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(changeLoader.fulfilled, (state, action) => {
      state.loader = action.payload;
    });
  },
});

export const { changeMode, setCurrentModal } = layoutSlice.actions;

export default layoutSlice.reducer;
