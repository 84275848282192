import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "../Components/Common/withRouter";
import Header from "./Header";
import Sidebar from "./Sidebar";
import SideBanner from "./SideBanner";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderComponent from "../Components/Common/LoaderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import DepositWithdraw from "./DepositWithdraw";
import {
  changeLoader,
  getAllEvents,
  getAccountBalance,
  getSportTypeList,
  getCasinos as getCasinosAPI,
  logoutAction,
  getUserData,
  getCasinoProviders,
  getCasinoGameType,
  getIsIPBlocked,
} from "../store/actions";

import socket from "../sockets/socket";
import Mainnav from "./Mainnav";
const Layout = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isLoggedIn, themeMode, userId, isNewUser } = useSelector((state) => ({
    isLoggedIn: state.User.isLoggedIn,
    themeMode: state.Layout.themeMode,
    userId: state.User?.User?._id,
    isNewUser: state.User.isNewUser,
  }));
  const getCasinos = () => {
    dispatch(getCasinosAPI());
    dispatch(getCasinoProviders());
    dispatch(getCasinoGameType());
  };
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", themeMode);
  }, [themeMode]);

  useEffect(() => {
    if (isNewUser) {
      navigate("/reset-password");
    } else if (!isLoggedIn) navigate("/login");
  }, [isLoggedIn]);

  const getBalance = () => {
    dispatch(getAccountBalance());
  };

  useEffect(() => {
    if (isLoggedIn) {
      getBalance();
      dispatch(getSportTypeList());
      socket.on("update-balance", (data) => {
        if (data && data.userId && data.userId == userId) getBalance();
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    let balanceInterval = null;
    if (pathname.includes("casino"))
      balanceInterval = setInterval(() => getBalance(), 2000);
    return () => clearInterval(balanceInterval);
  }, [pathname]);

  const getAllSports = () => {
    dispatch(getAllEvents()).then(() => dispatch(changeLoader(false)));
  };
  useEffect(() => {
    dispatch(changeLoader(true));
    dispatch(getIsIPBlocked())
      .then((res) => {
        if (res.payload.data) {
          navigate("/blocked");
        }
      })
      .catch((err) => navigate("/"));
    dispatch(getUserData()).then((res) => {
      if (!res.payload?.isActive) dispatch(logoutAction());
    });
    getAllSports();
    socket.on("events-updated", (data) => getAllSports());
    if (isLoggedIn) {
      const allSportInterval = setInterval(() => getAllSports(), 10000);
      return () => {
        clearInterval(allSportInterval);
      };
    }
  }, [isLoggedIn]);

  useEffect(() => {
    socket.on("logout-duplicate-user", (_id) => {
      if (userId === _id && isLoggedIn) {
        dispatch(logoutAction());
      }
    });
    socket.on("update-user", (ids) => {
      if (ids.includes(userId)) {
        dispatch(getUserData()).then((res) => {
          if (!res.payload?.isActive) dispatch(logoutAction());
        });
      }
    });
    return () => {
      socket.off("logout-duplicate-user");
      socket.off("update-user");
    };
  }, [isLoggedIn]);

  useEffect(() => {
    getAllSports();
    getCasinos();
  }, []);

  const checkURL = () => pathname.includes("sportsbook");
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        {isLoggedIn ? (
          checkURL() ? (
            <React.Fragment>
              <Header />
              <Mainnav />
              <DepositWithdraw />
              <div className="main iframe d-flex flex-wrap ">
                {props.children}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Header />
              <Mainnav />
              <DepositWithdraw />

              <div className="main d-flex flex-wrap mb-10">
                <Sidebar />
                <div className="main-content-section mb-10 mt-3">
                  {props.children}
                </div>
              </div>
              <Footer />
            </React.Fragment>
          )
        ) : (
          <div>{props.children}</div>
        )}
      </div>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <LoaderComponent isFullPage={true} />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default withRouter(Layout);
