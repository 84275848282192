import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMatchesFilter } from "./action";

//constants
const initialState = {
  matches: [],
  tournaments: [],
};

export const layoutSlice = createSlice({
  name: "Filter",
  initialState,
  extraReducers: (b) => {
    b.addCase(getMatchesFilter.fulfilled, (state, action) => {
      state.matches = action.payload?.matches;
      state.tournaments = action.payload?.tournament;
    });
    b.addCase(getMatchesFilter.rejected, (state, action) => {});
  },
});

export default layoutSlice.reducer;
