import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import classNames from "classnames";
import { useParams } from "react-router-dom";

// Icons
import CricketIcon from "../../assets/svg/CricketIcon";
import emptyIcon from "../../assets/images/empty-bet.png";
import { getPlacedBets } from "../../store/actions";
import { clearPlacesBets } from "../../store/bet/reducer";
import { formatDateWithTime } from "../../helpers/methods/formatDate";
import RawHtmlComponent from "./RawHtmlComponent";
import { roundToTwoDecimalPlaces } from "../../helpers/constants/functionConstants";

const BetTabComponent = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const { event, bets } = useSelector((state) => ({
    bets: state.Bet.bets,
    event: state.Market.event?.event,
  }));

  useEffect(() => {
    if (params.eventID) dispatch(getPlacedBets(params.eventID));
    else dispatch(clearPlacesBets());
  }, [params.eventID]);

  return (
    <React.Fragment>
      {bets && !bets.length ? (
        <Col sm="12">
          <div className="empty-betlist">
            <img src={emptyIcon} alt="" />
            <div className="emptybet-title">Your Betlist is empty</div>
            <div className="emptybet-sub-text">
              Click on odds to add a bet to the betlist
            </div>
          </div>
        </Col>
      ) : (
        <Col sm="12">
          <div className="betlist-section ">
            <div className="betlist-header p-2 d-flex justify-content-between">
              <div className="matched-bet">
                <div className="game-icon d-flex align-items-center">
                  <span className="me-2">
                    <CricketIcon />
                  </span>
                  <RawHtmlComponent
                    htmlString={event?.name?.replace(
                      " v ",
                      " <span>(vs)</span> "
                    )}
                  />
                </div>
              </div>
            </div>

            {bets.map((item, ky) => (
              <React.Fragment key={ky}>
                {item.data && item.data.length ? (
                  <React.Fragment>
                    <div className="bet-start">
                      <div className="bet-odd-title">
                        <h3>{item._id}</h3>
                      </div>
                    </div>
                    <div className="bet-start-record px-1">
                      <div className="header">
                        <div className="row m-0 valign-wrapper">
                          <div className="col s6 left-align">Selection</div>
                          <div className="col s2 center">Odds</div>
                          <div className="col s2 center">Stake</div>
                          <div className="col s2 center">
                            <span className="text-success">P </span>/
                            <span className="text-danger"> L</span>
                          </div>
                        </div>
                      </div>
                      {item.data.map((itm, k) => (
                        <React.Fragment key={k}>
                          <div
                            className={classNames("bet-record", {
                              "v-pink": itm.betType == "lay",
                              "v-blue": itm.betType == "back",
                            })}
                          >
                            <div className="row m-0 valign-wrapper">
                              <div className="col s6 left-align">
                                <div className="beatteam">
                                  {itm?.ball?.ball ? itm.ball.ball : ""}{" "}
                                  {itm.selectionName}
                                </div>
                                <small>
                                  {formatDateWithTime(itm.placedBetTime)}
                                </small>
                              </div>
                              <div className="col s2 center">
                                {itm.marketType == "FANCY" ? (
                                  <span>
                                    P: {itm.point} <br /> R:
                                    {itm.rate}
                                  </span>
                                ) : (
                                  itm.rate
                                )}
                              </div>
                              <div className="col s2 center">{itm.stake}</div>
                              <div className="col s2 center">
                                <span className="text-success">
                                  {roundToTwoDecimalPlaces(itm.profitAmount)}
                                </span>
                                <br />
                                <span className="text-danger">
                                  {roundToTwoDecimalPlaces(itm.lossAmount)}
                                </span>
                              </div>
                            </div>
                          </div>{" "}
                        </React.Fragment>
                      ))}
                    </div>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </Col>
      )}
    </React.Fragment>
  );
};

export default BetTabComponent;
