//LOGIN
export const GET_IP = process.env.REACT_APP_LOCATION_URL ?? "https://ipapi.co/json/";
export const GET_STAKE_BUTTONS = "/v1/common/default-stake-button";

export const AUTH_LOGIN = "/v1/auth/login";
export const AUTH_REFERSH_TOKEN = "/v1/auth/refresh-token";

export const SPORTS_LIST = "/v1/sports";
export const SPORTS_ALL_EVENT = "/v1/sports/all-event";
export const GET_EVENT_TYPES_RULES = "/v1/sports/rules";
export const GET_SITE_RULES = "/v1/sports/site/rules";
export const MARKET_BY_EVENT_ID = (eventId) => `/v1/markets/${eventId}`;
export const EVENT_BY_EVENTTYPE_ID = (eventTypeId) => `/v1/events/${eventTypeId}`;
export const SPORTS_BOOK = "/v1/sports/book";
export const GET_MARKET_ODDS = "/v1/markets/odds";
// export const GET_MARKET_ODDS = "https://api.deltaexch.games/v1/markets/odds";
export const MARKET_LIMIT_SETTING = (eventId) => `/v1/markets/limit-setting/${eventId}`;

export const LOGIN = "/v1/auth/login";
export const LOGOUT = "/v1/auth/logout";
export const RESET_PASSWORD = "/v1/user/password/update";

export const SEND_OTP = "/v1/user/otp/send";
export const VERIFY_OTP = "/v1/user/otp/verify";
export const USERNAME_EXISTS = "/v1/user/username/exists";
export const MOBILE_EXISTS = "/v1/user/mobile/exists";
export const ME = "/v1/user/me";
export const IS_IP_BLOCKED = "/v1/blacklist-ip/ip-list";
// Wallet
export const ACCOUNT_BALANCE = "/v1/account/balance";
export const WALLET_DEPOSIT_MANUAL = "/v1/deposit/manual";
export const ACCOUNT_STATEMENT = "/v1/account/statement";
export const GET_PAYMENT_MODE = "/v1/deposit/paymentMode";

// Bet Related
export const POST_PLACE_BET = "/v1/bet/place-bet";
export const GET_PLACED_BETS = (eventId) => `/v1/bet/placed-bets/${eventId}`;

export const GET_USER_BETS = "/v1/bet";
export const GET_MATCHES_FILTER = "/v1/events/filter/all";

export const GET_CASINO_EVENT = (casinoId) => `/v1/casino/${casinoId}`;

// user bank details related

export const GET_USER_BANK_DETAILS = `v1/bank-details/get-bank-details`;
export const GET_BANK_NAMES = `v1/bank-details/get-bank-names`;
export const ADD_BANK_DETAILS = "/v1/bank-details/add-bank-details";
export const EDIT_BANK_DETAILS = (id) => `/v1/bank-details/edit-bank-details/${id}`;

// casino related
export const GET_CASINOS = "/v1/casino";
export const GET_CASINO_PROVIDERS = "/v1/casino/casino-providers";
export const GET_CASINO_GAME_TYPES = "/v1/casino/casino-gametypes";
export const CREATE_WITHDRAW_REQUEST = "/v1/withdrawal/create-withdraw-request";
export const GET_WITHDRAW_REQUEST_DATA = `v1/withdrawal/get-withdraw-requests`;
export const CANCEL_WITHDRAW_REQUEST_DATA = (withdrawId) =>
  `v1/withdrawal/cancel-withdraw-requests/${withdrawId}`;
export const GET_TRANSACTION_HISTORY = `v1/account/transaction-history`;

//Stake Buttons
export const GET_DEFAULT_STAKE_BUTTONS = "/v1/common/default-stake-button";
export const UPDATE_DEFAULT_STAKE_BUTTONS = "/v1/common/default-stake-button/update";

// user profile
export const GET_USER_PROFILE = `/v1/account/profile`;

export const GET_GENERAL_SETTINGS = `v1/general-settings`;
export const SITE_MAINTANANCE_SETTINGS = `v1/general-settings/siteMaintenance`;
export const CASINO_SETTINGS = `v1/general-settings/casinoToggle`;
