import { createAsyncThunk } from '@reduxjs/toolkit';
import { placeBet, placedBets } from '../../helpers/apiHelper/apis';

export const placeBetAction = createAsyncThunk('Bet/placeBetAction', async (data) => {
  const response = await placeBet(data);
  return response.data;
});

export const getPlacedBets = createAsyncThunk('Bet/getPlacedBets', async (data) => {
  const response = await placedBets(data);
  return response.data;
});
